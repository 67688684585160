.annotate .page-options .col-2{
  -ms-flex: 0 0 38%;
  flex: 0 0 38%;
  max-width: 38%;
}
.annotate .page-options .col-8{
  -ms-flex: 0 0 24%;
  flex: 0 0 24%;
  max-width: 24%;
}
.annotate .page-options .col-2:first-child {
    border-right: 1px solid #ece2e2;
    padding-right: 0;
}
.annotate .page-options .col-2:last-child {
    border-left: 1px solid #ece2e2;
    padding-left: 0;
}
.annotate .page-options .col-2:first-child, .annotate .page-options .col-2:last-child {
  height: calc(100vh - 60vh);
}
.annotate .page-options, .annotate .tab-content .tab-pane .tab-child > div:first-child {
    position: relative;
}
.annotate .k-panelbar>.k-item>.k-link {
    padding: 4px 10px;
}
.annotate .wrapper-content {
  /*padding: 0 10px; */
}
.annotate .k-item:nth-child(2) .page-options {
    position: relative;
    height: calc(100vh - 370px);
}
.annotate .page-options .col-2:first-child > .wrapper-content, .annotate .page-options .col-2:last-child > div.target {
    overflow: auto;
    height: 100%;
}
.annotate .maximize-window .page-options.variable .col-2:first-child > .wrapper-content, .annotate .maximize-window .page-options.variable .col-2:last-child > div.target {
  height: 92%;
}
.annotate .page-options.variable .col-2:first-child > .wrapper-content, .annotate .page-options.variable .col-2:last-child > div.target {
  height: 87%;
}
.annotate .page-options .col-2:first-child > .wrapper-content {
    direction: rtl;
}
.annotate .page-options .col-2:first-child > .wrapper-content .tree-view {
    direction: ltr;
}
.annotate .page-options .col-2:first-child > .wrapper-content >div {
    position: relative;
    left: -15px;
    right: 0;
}
.annotate .target .manage-column-name-item {
  padding-left: 30px;
  margin-left: -30px;
}
.annotate .tree-view img.p_b_2 {
  width: 16px;
  margin-right: 3px;
  margin-top: -3px;
}
.annotate .field_group {
  padding: 5px 0;
}
.annotate legend {
  padding-left: 15px;
}
.annotate .carry-forward {
  font-weight: bold;
    padding: 1px;
    margin-left: 3px;
    background: #00ffff;
    border-radius: 7px;
    font-size: 11px;
}
.annotate  .react-contextmenu {
  z-index: 1;
}
.annotate .where-condition {
  font-weight: bold;
    padding: 1px;
    margin-left: 3px;
    background: #00ffff;
    border-radius: 7px;
    font-size: 11px;
}

.annotate .hardcode {
  font-weight: bold;
    padding: 1px;
    margin-left: 3px;
    background: #4bff00;
    border-radius: 7px;
    font-size: 11px;
}
.annotate .valign-middle {
  vertical-align: middle;
}
.annotate .expression {
  font-weight: bold;
    padding: 1px;
    margin-left: 3px;
    background: #ff42df;
    border-radius: 7px;
    font-size: 11px;
}
.annotate .expressionIntermediate {
  font-weight: bold;
  padding: 1px;
  margin-left: 3px;
  background: #ff9dee;
  border-radius: 7px;
  font-size: 11px;
}

.annotate .pivot-operation-column {
  font-weight: bold;
    padding: 1px;
    margin-left: 3px;
    background: #ff42df;
    border-radius: 7px;
    font-size: 11px;
}
.annotate .applyProcessFlow {
  font-weight: bold;
    padding: 1px;
    margin-left: 3px;
    background: #4bff00;
    border-radius: 7px;
    font-size: 11px;
}
.annotate .gby-column {
    font-weight: bold;
    padding: 1px;
    margin-left: 3px;
    background: #ff6093;
    border-radius: 7px;
    font-size: 11px;
}

.annotate .oby-column {
    font-weight: bold;
    padding: 1px;
    margin-left: 3px;
    background: #a59aff;
    border-radius: 7px;
    font-size: 11px;
}

.annotate .ct-column {
    font-weight: bold;
    padding: 1px;
    margin-left: 3px;
    background: #ffc107;
    border-radius: 7px;
    font-size: 11px;
}
.annotate .annotate-contextmenu-where-condition-dialog .k-widget.k-window.k-dialog {
    min-width: 320px;
    width: 320px;
    height: 170px;
}

.annotate .annotate-contextmenu-rename-column-dialog .k-widget.k-window.k-dialog {
    min-width: 320px;
    width: 320px;
    height: 220px;
}

.annotate .annotate-contextmenu-hardcode-dialog .k-widget.k-window.k-dialog {
    min-width: 320px;
    width: 320px;
    height: 240px;
}

.annotate .annotate-contextmenu-hardcode-dialog.Hardcode .k-widget.k-window.k-dialog {
  height: 210px;
}

.annotate .annotate-contextmenu-expression-dialog .k-widget.k-window.k-dialog {
    min-width: 740px;
    width: 90%;
    height: auto;
}
.annotate-contextmenu-expression-dialog .table th, .annotate-contextmenu-expression-dialog .table td, .annotate-remove-mapped-column-dialog .table th, .annotate-remove-mapped-column-dialog .table td {
    padding: 4px 2px;
}
.annotate-contextmenu-expression-dialog .table td, .annotate-remove-mapped-column-dialog .table td {
    vertical-align: middle;
}
.annotate-contextmenu-expression-dialog .table td .am-dropdown {
    width: 90px;
}
.annotate-contextmenu-expression-dialog .table th:nth-child(3) {
    width: 100px;
}
.annotate-contextmenu-expression-dialog .table th:nth-child(3), .annotate-contextmenu-expression-dialog .table td:nth-child(3){
  padding-left: 5px !important;
}
.annotate .bottom-border {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}
.regex-columnMaaping-detail .regex-button-wrapper {
  text-align: center;
}

.annotate .col-4 .am-form-field.align-right {
  text-align: right;
}

.annotate .highlight-background {
  background: yellow;
}

.annotate .rename-column {
  font-weight: bold;
    padding: 1px;
    margin-left: 3px;
    background: #a99aff;
    border-radius: 7px;
}
.annotate .frequency-column {
    font-weight: bold;
    padding: 1px;
    margin-left: 3px;
    background: #fd8993;
    border-radius: 7px;
}
.annotate .page-options .mapped-column-wrapper .col-2:first-child, .annotate .page-options .mapped-column-wrapper .col-2:last-child {
    height: calc(100vh - 76vh);
}
.annotate .page-options .mapped-column-wrapper .col-2.type-1:first-child, .annotate .page-options .mapped-column-wrapper .col-2.type-1:last-child {
    height: calc(100vh - 70vh);
}
.annotate .maximize-window .page-options .mapped-column-wrapper .col-2.type-1:first-child, .annotate .maximize-window .page-options .mapped-column-wrapper .col-2.type-1:last-child {
    height: calc(100vh - 30vh);
}
.annotate .maximize-window .page-options .col-2.value-section:first-child, .annotate .maximize-window .page-options .col-2.value-section:last-child {
  height: calc(100vh - 36vh);
}
.annotate-contextmenu-hardcode-dialog .dropdown .am-dropdown {
  margin: 0px 0px 5px 27px !important
}
.annotate .job-list-wrapper {
  margin-top: 10px;
}
.annotate .execution-plan .job-list-wrapper .am-rich-grid-table {
  height: calc(100vh - 270px) !important;
}
.annotate .execution-plan .k-dialog-buttongroup {
  justify-content: center;
}
body .am-rich-grid-table .k-alt.sensitive, .sensitive {
    background: #eee;
    opacity: 0.5;
}
.sensitive .am-textbox{
  background: transparent;
}
.remove-mapped-line .am-button {
  padding: 0 !important;
  margin: 0;
  height: 14px;
  width: 14px;
  margin-left: 5px;
  background-color: red;
  border-color: red;
  margin-top: -3px;
}
.remove-mapped-line .am-button span {
  font-size: 12px !important;
}
.rgt-cell .remove-mapped-line:hover .am-button span {
  line-height: 0.2;
}
.remove-mapped-line .k-i-x {
  line-height: 1.2;
}
.annotate .annotate-remove-mapped-column-dialog .k-widget.k-window.k-dialog {
    min-width: 500px;
    width: 500px;
    height: auto;
    max-height: calc(100vh - 100px);
}
.am-treeview .highlight-table {
  background: #2efb2e;
  border-radius: 6px;
  font-weight: 600;
}

body .mapping-specs .k-spreadsheet {
  width: 100%;
  height: calc(100vh - 24vh)
}
body .k-spreadsheet-sheets-bar-add, .mapping-specs .k-spreadsheet .k-tabstrip-wrapper, .mapping-specs .k-tabstrip-items .k-spreadsheet-sheets-remove  {
  display: none;
}

.spread-sheet-grid .button-panel {
  margin-top: 5px;
}
.spread-sheet-grid .button-panel .action-btn{
  margin-left: 15px;
}
.spread-sheet-grid .myAlertError-top {
  z-index: 1;
  top:45px;
}

.spread-sheet-grid .myAlertError-top.show {
  display: block !important;
}

.annotate .table-filter .am-radio-label {
  margin-right: 8px;
}
.annotate .regex-columnMaaping-detail .am-multi-select {
  width: 100% !important;
}
.annotate .page-options .mapped-column-wrapper .variable-row.col-2 {
  height: 32px !important;
}
.regex-columnMaaping-detail .k-multiselect-wrap {
  padding-right: 0;
}
.regex-columnMaaping-detail .k-multiselect-wrap li.am-button {
  text-align: left;
  align-items: left;
  justify-content: left;
}
.regex-columnMaaping-detail .k-multiselect-wrap .am-button .k-select {

}
.m-t-18 {
  margin-top: 18px;
}

.regex-columnMaaping-detail .field_group {
  padding: 0 10px;
}
.p-b-6 {
  padding-bottom: 6px;
}

.annotate .regex-columnMaaping-detail legend {
  padding-top: 6px;
}
.annotate .regex-columnMaaping-detail .field_group.row div.am-dropdown {
  width: 100%;
}
.annotate-contextmenu-applyProcessFlow-dialog  .field_group .row div.am-dropdown {
width: 100%;
display: block;
}
.annotate .annotate-contextmenu-applyProcessFlow-dialog .field_group {
  padding: 5px 10px;
}

.annotate .annotate-contextmenu-sortApplyProcessFlow-dialog .field_group {
  padding: 5px 10px;
}

.annotate-advance-match-dialog .k-widget.k-window.k-dialog {
  width: 750px;
  height: auto;
}

.annotate .annotate-contextmenu-applyProcessFlow-dialog .k-widget.k-window.k-dialog {
  width: calc(100% - 10px);
    height: calc(100% - 20px);
}

.annotate .annotate-contextmenu-sortApplyProcessFlow-dialog .k-widget.k-window.k-dialog {
  width: calc(100% - 200px);
    height: calc(100% - 200px);
}

.readOnlydiagram{
  overflow:auto;
  overflow-x:auto;
  width: calc(100% - 0px);
  height: calc(100vh - 240px);
}

.data-operation .rich-grid td .expression-button .k-dialog-content {
    width: 100%;
}
.data-operation .k-grid-content .expression-button table{
	table-layout: auto;
    width: 100%;
}
.am-rich-grid-table .expression-button .expression-detail thead {
    border-color: transparent;
}
.am-rich-grid-table tbody .expression-button tr:hover {
  background-color: transparent;
}
.am-rich-grid-table tbody .expression-button thead th {
  border-top: 1px solid #c8ced3;
}
.annotate-contextmenu-expression-dialog.expression-button .table td{
  border: 0;
  border-top: 1px solid #c8ced3;
}
.annotate-contextmenu-expression-dialog.expression-button .table tbody tr:first-child td {
  border-bottom: 0;
}
.annotate-contextmenu-hardcode-dialog.expression-button label.pro-label {
  white-space: nowrap;
}
.annotate-contextmenu-hardcode-dialog.expression-button .k-content {
  text-align: left;
}
.annotate .fa-check-circle.status {
  font-size: 25px;
  margin: 1px 0 0 0;
}
.alert.myAlertInfo-topS {
   z-index: 999999;
}
.data-operation .rich-grid td.text-editor .k-dialog-content {
    width: 100%;
}
.data-operation .rich-grid td.text-editor .k-dialog-content .log_Editor.execute_all .CodeMirror {
    height: calc(100vh - 164px);
  }
.mapping-specs .align-center.m-15 {
  margin-top: 15px;
}
.mapping-specs .align-center.m-15 .k-i-download {
  margin: 2px 3px 0 0;
}
.job-list-wrapper .k-virtual-content .am-dropdown-wrap {
  text-transform: lowercase;
}
.badge.remove-mapped-line .am-button {
    position: absolute;
    left: -19px;
    top: 5.5px;
    display: none;
}
.badge.remove-mapped-line .am-button.k-primary .k-icon {
    top: -5px;
}
.remove-mapped-line.badge:hover .am-button{display: block;}
.remove-mapped-line.badge {
    position: relative;
    padding: 4px;
    font-size: 11px;
    margin: 2px 0;
    cursor: pointer;
    margin-left: 18px;
}
.regex-columnMaaping-detail .badge-button-wrapper {
  font-size: 15px;
  margin-bottom: 8x;
}
.regex-columnMaaping-detail .badge-button-wrapper .badge {
  margin-right: 5px;
}
.table-name.remove-mapped-line {
  cursor: auto !important;
}
.regex-columnMaaping-detail .rich-grid .k-grid-table tbody tr td {
  border-bottom: 1px solid #ccc
}
.regex-columnMaaping-detail .warning > .am-button{
  background-color: #ffc107;
  border-color: #ffc107;
}
.regex-columnMaaping-detail .warning > .am-button:hover{
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.apply-process-flow-constructive .k-widget > .k-content.k-window-content.k-dialog-content {
padding-left: 16px;
padding-right: 16px;
}
.apply-process-flow-constructive .constructive-operation .k-header > .k-content, .annotate .apply-process-flow-constructive .k-child-animation-container > div:first-child{
  position: initial;
}
.annotate .apply-process-flow-constructive legend {
  padding-left: 0
}
.annotate-contextmenu-expression-dialog .table td[colspan="7"] {
    padding: 0;
}
.table-scroll table {
    width: 100%;
    table-layout: fixed;
}
.annotate-contextmenu-expression-dialog .table .table-scroll td:first-child,
.annotate-contextmenu-expression-dialog .table-inner-scroll .table th:first-child{
  width: 200px;
}
.annotate-contextmenu-expression-dialog .table .table-scroll td:nth-child(2),
.annotate-contextmenu-expression-dialog .table-inner-scroll .table th:nth-child(2){
  width: 220px;
}

.annotate-contextmenu-expression-dialog .table .table-scroll td:nth-child(3),
.annotate-contextmenu-expression-dialog .table-inner-scroll .table th:nth-child(3){
  width: 90px;
}
.annotate-contextmenu-expression-dialog .table .table-scroll td:nth-child(4),
.annotate-contextmenu-expression-dialog .table-inner-scroll .table th:nth-child(4){
  width: 88px;
}
.annotate-contextmenu-expression-dialog .table .table-scroll td:nth-child(5),
.annotate-contextmenu-expression-dialog .table-inner-scroll .table th:nth-child(5){
  width: 87px;
}
.annotate-contextmenu-expression-dialog .table .table-scroll td:nth-child(6),
.annotate-contextmenu-expression-dialog .table-inner-scroll .table th:nth-child(6){
  width: 450px;
}
.annotate-contextmenu-expression-dialog .table-inner-scroll th {
  font-weight: bold;
}
.table-scroll {
    max-height: calc(100vh - 306px);
    overflow: auto;
}
.annotate-contextmenu-sortColumns-dialog .pivot-grid.row {
    margin: 0 -5px;
}
.annotate-contextmenu-sortColumns-dialog .pivot-grid.row .field_group {
    padding: 5px 10px;
}
.annotate-contextmenu-sortColumns-dialog .col-6 .height_fixed_step1 .am-rich-grid-table {
    height: calc(100vh - 180px) !important;
}
.annotate .window-action-bar {
  position: absolute;
  top: -20px;
  right: 30px;
}

.annotate .window-action-bar .am-button {
    width: 20px !important;
    height: 20px;
    padding: 2.5px 8px;
}
.annotate .maximize-window .page-options .col-2:first-child, .annotate .maximize-window .page-options .col-2:last-child{
    height: calc(100vh - 30vh);
}
.annotate .maximize-window .page-option .flexible-height {
  height: calc(100vh - 21vh);
}
.annotate .page-option .flexible-height {
  height: calc(100vh - 48vh);
}
.m-t-5 {
  margin-top: 5px;
}
.minimize-window .page-options .row, .minimize-window .page-option .row {
    display: none;
}
.highlight-yellow input, .highlight-yellow span {
  background-color: #ffff00 !important
}
.annotate .job-extra-feature label span.k-multiselect.k-header {
  width: 100% !important;
}
.annotate .job-extra-feature label span.k-multiselect.k-header ul.k-reset li.am-button {
  width: 108px !important;
}
.annotate .execution-plan .align-center span.fa-refresh {
  top: 0;
  margin-right: 4px;
}
.table-version .col-3 {
  float: left;
}
.table-version .card {
  width: 100% !important;
  height: 270px;
}
.table-version .card .k-item {
  margin-bottom: 0;
}
.remove-column {
  background-color: #ff0000;
  padding-left: 10px !important;
}
.new-column {
  background-color: #008000;
  color:#fff;
  padding-left: 10px !important;
}
.annotate .regex-columnMaaping-detail .annotate-contextmenu-expression-dialog .k-widget.k-window.k-dialog {
  width: 90%;
}
.conflict-expression > div {
    display: inline-block;
    width: 100%;
}
.conflict-expression .am-textbox {
  background-color: #ffc107;
  width: calc(100% - 23px) !important;
  float: left;
}
.conflict-expression input[type="radio"] {
    width: 13px;
    margin-top: 7px;
    margin-right: 10px;
    float: left;
}
.conflict-expression-dialog {
  display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
}
.conflict-expression-dialog .d-table{
  height: 100%;
  display: table;
}
.conflict-expression-dialog > div{
  -webkit-box-flex: 0;
-ms-flex: 0 0 23px;
flex: 0 0 23px;
max-width: 23px;
}
.conflict-expression-dialog .d-table-cell{
  display: table-cell;
  vertical-align: middle;
}
.conflict-expression-dialog .expression-detail {
    -ms-flex: calc(100% - 23px);
    flex: calc(100% - 23px);
    max-width: calc(100% - 23px);
}
.annotate .custom-switch .k-switch {
  width: 96px;
}
.annotate .custom-switch .k-switch .k-switch-label-on {
  left: -68px
}
.annotate .custom-switch .k-switch .k-switch-container {
  line-height: 24px;
}
.annotate .custom-switch .k-switch .k-switch-handle {
  top: -2px;
}
.custom-switch .k-switch .k-switch-handle > span {
  line-height: 15px;
}
.annotate .annotate-contextmenu-hardcode-dialog.multiple-hardcode .k-widget.k-window.k-dialog {
  max-height: 500px;
  height: auto !important;
}
.multiple-hardcode .am-form-line-left .input-conflic {
    width: 148px;
    margin-left: 12px;
    display: inline-block;
}
.multiple-hardcode .am-form-line-left .input-conflic + .input-conflic{
  margin-top: 5px;
}
.multiple-hardcode .am-form-line-left.conflict-expression .pro-label{
  vertical-align: top;
}
.expression_tile {
    display: inline-block;
    width: 100%;
}
.expression_tile .expression_tile_radio {
    display: inline-block;
    width: 20px;
    float: left;
    padding: 24px 0;
    padding-left: 6px;
}
.expression_tile .expression_tile_inputs{
  display: inline-block;
  width: calc(100% - 20px);
  float: left;
}
.annotate .annotate-contextmenu-hardcode-dialog .k-widget.k-window.k-dialog {
    min-width: 380px;
    width: 550px;
}
.expression_tile_inputs .text-box-display{
      margin: 0px 0px 5px 14px;
      background-color: #ffc107;
}
.Expression .text-box-display, .Hardcode .text-box-display {
  width: 371px;
}
.annotate-contextmenu-hardcode-dialog.expression-button .expression_tile_inputs label.pro-label{
    padding-left: 5px;
  }
.expression_tile_list .expression_tile + .expression_tile{border-top:1px solid #000;    padding-top: 10px;}

.annotate .job-list-wrapper .am-dropdown.group-classifier-dropdown span {
  text-transform: initial
}
.auto-value-map-btn {
  position: absolute;
  margin-top: 5px;
  margin-left: -45px;
  z-index: 1;
}
.annotate .annotate-contextmenu-applyProcessFlow-dialog .readOnlydiagram {
  height: calc(100vh - 265px)
}
.annotate.data-operation .rich-grid td.expression-dialog-button-wrapper .k-dialog-content {
  width: auto;
}
.annotate .page-options .col-2 .pivot-order-dialog label {
  border: 0;
  height: 32px !important;
}
.annotate .action-wrapper {
  margin-left: 5px;
}
.annotate .title-wrapper.bottom-move-button {
  margin-top: 8px
}
.annotate-contextmenu-hardcode-dialog .ui-dialog-container .ui-dialog .pro-label.col-sm-4 {
  width: 125px;
}
.annotate .multiple-expression-dialog .expression-wrapper {
  height: 490px;
  overflow-y: auto;
}
.regex-columnMaaping-detail .column-break-line-drow {
  border-top: 1px solid #ccc;
  padding-top: 5px;
  margin-top: 5px;
}
.font-bold {
  font-weight: bold !important;
}
.navigation-bar-menu .am-treeview .item .status-yellow {
  background-color: yellow !important;
  border-radius: 7px;
}
.navigation-bar-menu .am-treeview .item .status-red {
  background-color: red !important;
  border-radius: 7px;
}
.navigation-bar-menu .am-treeview .item .column-name {
  padding: 2px;
}
.navigation-bar-menu .am-treeview .item .column-name .value-node {
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 360px;
}
.navigation-bar-menu .am-treeview .item .column-name .value-node.no-carry-forward {
  max-width: 380px;
}
.navigation-bar-menu .am-treeview .item .column-label, .navigation-bar-menu .am-treeview .item .column-length {
  font-size: 10px;
  color: #727290
}
.pivot-order-dialog .dropdown-list > .am-dropdown, .pivot-order-dialog .dropdown-list > .am-multi-select {
  width: 78% !important;
}

.pivot-order-dialog .dropdown-list.pivot-list .am-multi-select {
  width: 170px !important;
  margin-right: 10px;
}
.undo-wrapper {
  padding-left: 8px;
  font-size: 14px;
}
.w-85 {
    width: 85% !important;
}
.annotate .pivot-order-dialog .regex-columnMaaping-detail .matrix-list .am-rich-grid-table {
  height: calc(100vh - 27vh) !important;
}
.annotate .definition-dialog legend {
    padding-top: 6px;
}
.annotate .definition-dialog .height_fixed_step1 .am-rich-grid-table {
  height: calc(100vh - 280px) !important;
}
.annotate .regex-columnMaaping-detail .height_fixed_step1 .am-rich-grid-table {
  height: calc(100vh - 439px) !important;
}
.annotate .regex-columnMaaping-detail .maximize-window .flexible-height .height_fixed_step1 .am-rich-grid-table {
  height: calc(100vh - 40vh) !important;
}

.annotate .am-treeview .item.blink-background, .annotate .am-treeview .item.blink-background .column-name  {
  background-color: #000 !important;
  color: #fff !important;
}
.annotate .regex-columnMaaping-detail .page-option {
  position: relative;
}
.annotate .regex-columnMaaping-detail .window-action-bar.second {
  right: 19px;
}
.annotate .job-list-wrapper .rich-grid .k-grid-table tbody tr td {
  height: 38px !important;
}
.annotate .regex-columnMaaping-detail .definition-dialog .height_fixed_step1 .am-rich-grid-table {
  height: calc(100vh - 435px) !important;
}
.annotate .regex-columnMaaping-detail.definition-window .k-widget.k-window.k-dialog {
  width: 70%;
  height: auto;
}
.Highlight {
  position: relative !important;
}
.Highlight--container {
  cursor: pointer;
  position: absolute;
  display: flex;
  background: rgba(255, 226, 143, 1);
  transition: background 0.3s;
}
.annotate .col-3.target-tablelist {
  height: calc(100vh - 21vh);
  overflow-y: auto;
  margin-left: -15px;
}
.annotate .annotate-variable-button {
  font-weight: bold;
    padding: 2px 4px;
    margin-left: 3px;
    background: #3f4143;
    border-radius: 7px;
    font-size: 11px;
    color: #fff;
    display: initial;
}
.pdf-annotate .target-tablelist .annotate-variable-button {
  display: none;
}
.annotate .source-classifier .am-multi-select {
  width: 210px !important;
}
.annotate .multiple-target-column .am-multi-select {
  width: 100% !important;
  display: flex;
}
.annotate .k-spreadsheet-action-bar {
  max-height: 100px;
  overflow-y: auto;
}
.annotate .create-date {
  font-size: 13px;
  font-style: oblique;
  margin: 1px 0 0 5px;
  color: #5c89b5;
  margin-right: 15px;
  background: none;
  cursor: inherit;
}
.annotate .create-date-wrapper {
  text-align: right;
  margin-top: -20px;
}

.annotate .where-order-dialog .k-dialog-wrapper .k-dialog {
  width: 65%;
  height: calc(100vh - 240px);
}
.annotate .where-order-dialog .regex-columnMaaping-detail .height_fixed_step1 .am-rich-grid-table {
  height: calc(100vh - 330px) !important
}
.annotate .where-order-dialog.additional-wherecondtion .k-dialog-wrapper .k-dialog {
  height: calc(100vh - 176px);
}
.annotate .draft-save-wrapper {
  position: relative;
  z-index: 1;
}
.annotate .k-draft-save {
  background: #28a745;
  border-color: #28a745;
  position: absolute;
  right: 0;
  font-size: 12px
}
.annotate .regex-columnMaaping-detail .height_fixed_step1 .height_fixed .am-rich-grid-table {
  height: calc(100vh - 235px) !important;
}
.annotate .m-t-r-5-10 {
  margin-top: 5px;
  margin-right: 10px;
}
.annotate .annotate-contextmenu-applyProcessFlow-dialog .field_group.variable-value-wrapper{
  height: calc(100vh - 253px)
}

.annotate .annotate-contextmenu-applyProcessFlow-dialog .field_group.variable-value-wrapper .target-tablelist.tree-view {
  height: calc(100vh - 253px);
  overflow-y: auto;
}
.annotate .annotate-contextmenu-applyProcessFlow-dialog .m-l-n-30 {
  margin-left: -30px;
}
.annotate .annotate-contextmenu-applyProcessFlow-dialog .k-multiselect-wrap .am-button span {
  font-size: 12px;
}
.annotate .annotate-contextmenu-applyProcessFlow-dialog .field_group.variable-value-wrapper .target-tablelist.tree-view .navigation-bar-menu .am-treeview .item .column-name {
  cursor: pointer;
}
.annotate .annotate-contextmenu-applyProcessFlow-dialog .field_group.variable-value-wrapper .target-tablelist.tree-view .navigation-bar-menu .am-treeview .item .column-name:hover, .annotate .annotate-contextmenu-applyProcessFlow-dialog .field_group.variable-value-wrapper .target-tablelist.tree-view .navigation-bar-menu .am-treeview .item .column-name.selected-node{
  background-color: #007bff !important;
  color: #fff;
  border-radius: 5px;
  transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease
}
.annotate .annotate-contextmenu-applyProcessFlow-dialog .disabled-click-event {
  opacity: 0.3;
  pointer-events: none;
}
.annotate .table tr.first-row:first-child th, .annotate .table tr.first-row:first-child td {
  border: 0;
}
.annotate .annotate-contextmenu-target-column-value-dialog .k-widget.k-window.k-dialog {
    min-width: 520px;
    width: 520px;
    height: 140px;
}
.annotate .annotate-contextmenu-target-column-value-dialog .ui-dialog-container .ui-dialog .target-variable-value {
  width: 390px;
}
.annotate-contextmenu-applyProcessFlow-dialog .ui-dialog-container .ui-dialog .ui-dialog-buttonpane {
    height: 80px;
}
.annotate-contextmenu-applyProcessFlow-dialog .ui-dialog-container .ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
  width: 100%;
  display: flex;
}
.custom_save_pop .width-full {
  width: 300px !important;
}
.annotate .execution-plan .custom_save_pop .k-dialog-buttongroup {
  justify-content: right;
}

.annotate-contextmenu-hardcode-dialog.Hardcode .ui-dialog-container .ui-dialog .pro-label.col-sm-4 {
  width: 78px;
}
.Hardcode .text-box-display {
  width: 418px;
}
.annotate-contextmenu-applyProcessFlow-dialog .p-t-15 {
  padding-top: 15px;
}
.execution-plan .job-list-wrapper .ui-dialog-buttonpane .ui-dialog-buttonset {
  width: 100%;
  text-align: center;
}
.annotate .regex-columnMaaping-detail.inline-block {
  display: inline-block;
}
.annotate .where-order-dialog.additional-wherecondtion ul {
  font-weight: inherit;
  line-height: inherit;
  overflow: inherit;
  padding-left: inherit;
}
.annotate .where-order-dialog.additional-wherecondtion ul .k-item {
  border-width: 1px;
  padding: 0;
}
.annotate .middle-content {
  display: table;
  margin: 0 auto;
}
.data-operation .rich-grid td .data-operation.data_viewer_popup .DaveViewer_height .k-dialog-content{
  width: 100%;
}
.sample-filter-wrapper fieldset {
  width: 118px;
  text-align: center;
}
.sample-filter-wrapper fieldset .field_group {
  padding-bottom: 1px;
}
.annotate .design{
  height: calc(100vh - 110px);
}

/* .annotate .design .display-mode {
  height: calc(100vh - 160px);
  overflow-y: auto;
  margin-right: -4px;
} */
.annotate .design .display-mode .column_row {
  height: calc(100vh - 190px);
}

.annotate .design .display-mode .column_row .treeview.col-3, .annotate .design .display-mode .column_row .proc-report-tablelist{
  border-left: 1px solid rgba(33,37,41,0.125);
  overflow: auto;
  height: 100%;
}
.annotate .design .display-mode .column_row .proc-report-tablelist {
  border-right: 1px solid rgba(33,37,41,0.125);
}
.annotate .design .k-textbox.col-11 {
  width: 77%;
}
.annotate .design .footnote .k-textbox.col-11 {
  width: 70%;
}
.annotate .design .m-r-7 {
  margin-right: 7px;
}
.annotate .design .column-section, .annotate .design .row-section, .annotate .design .block-section, .annotate .design .group-section {
  height: 100%;
  background-color: #ddf7ff;
  overflow-y: auto;
  color: #000;
  border-radius: 5px;
  border: 1px solid #e7e8da;
  border-top-left-radius: 0;
}
.annotate .design .row-section-btn, .annotate .design .row-section, .annotate .design .row-section-btn, .annotate .design .row-element .row-box {
  background-color: #ddf7ff;
  color: #000;
}
.annotate .design .row-section {
  overflow-x: auto;
}
.annotate .design .row-section .row-box, .annotate .design .row-element .row-box {
  border-right: 1px solid #c7c3c3;
  height: 100%;
  cursor: pointer;
  padding: 3px;
  position: relative;
}
.annotate .design .row-section .row-box:hover, .annotate .design .row-element .row-box:hover, .annotate .design .group-section .group-box:hover, .annotate .design .column-section .column-box:hover, .annotate .design .block-section .block-box:hover {
  background-color: #c9f1fd;
}
.annotate .design .group-section .group-box, .annotate .design .column-section .column-box, .annotate .design .block-section .block-box {
  cursor: pointer;
  padding: 3px;
  border-bottom: 1px solid #c7c3c3;
  position: relative;
}
.annotate .design .group-section .group-box .title, .annotate .design .column-section .column-box .title {
  min-height: 20px;
}
.annotate .design .column-section .column-box.treatment-column {
  background-color: #8989ff;
}
.annotate .design .column-section-btn {
  background-color: #3c405b;
  color: #fff;
}
.annotate .design .block-section-btn, .annotate .design .block-section {
  overflow-x: hidden;
  border-radius: 5px;
}
.annotate .design .small-height.group {
  height: 20%;
}
.annotate .design .small-height {
  height: 15%;
  overflow-y: auto;
}
.annotate .design .small-height-only {
  height: 20%;
}
.annotate .design .column-list.full-height {
  height: 100%;
}
.annotate .design .big-height {
  height: 48%;
  overflow-y: auto;
}
.annotate .design .big-two-height {
  height: 47%;
  overflow-y: auto;
}
.annotate .design .medium-height {
  height: 73%;
  overflow-y: auto;
}
.annotate .design .full-height {
  height: 77%;
  overflow-y: auto;
}
.annotate .design .small-btn .k-button {
  font-size: 13px;
}
.annotate .design .m-t-15 {
  margin-top: 15px;
}
.annotate .design .event-dialog.column .ui-dialog-container .ui-dialog {
  width: 950px !important;
  height: calc(100vh - 20px) !important;
}
.annotate .design .event-dialog.block .ui-dialog-container .ui-dialog {
  width: calc(100% - 10px);
  height: calc(100vh - 20px);
}
.annotate .design .event-dialog .width-53 {
  width: 53%
}
.annotate .design .k-dropdown.table-list {
  width: 200px;
}
.annotate .design .event-dialog .am-dropdown.variable-list {
  width: 230px;
  flex: none;
}
.annotate .design .dashed {
  border: 2px dashed #000;
}
.annotate .design .title-section {
  text-align: center;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
}
.annotate .design .section-control {
  right: 0;
  z-index: 1;
  padding-bottom: 5px;
  text-align: right;
  border-bottom: 1px solid rgba(33,37,41,0.125);
}
.annotate .design .p-r-10 {
  padding-right: 10px;
}
.annotate .design .analysis-grid .am-rich-grid-table {
  margin-top: 10px;
}
.annotate .design .block-section .block-box .label {
  border-bottom: 1px solid #c7c3c3;
  padding-bottom: 2px;
}
.annotate .design .am-form-field {
  margin-bottom: 0 !important;
}
.annotate .design .block-section .block-box .treatment-title {
  padding-top: 5px;
}
.annotate .design .width-100 {
  width: 100px;
}
.annotate .design .width-50 {
  width: 50px;
}
.annotate .design .k-textbox.width-70 {
  width: 70px;
}
.annotate .design .k-textbox.width-110 {
  width: 110px;
}

.annotate .design .k-panelbar>.k-item>.k-link {
  padding: 4px 10px !important;
  font-weight: bold;
}
.annotate .design .analysis-report {
  height: calc(100vh - 325px);
  width: 98%;
  margin-left: 5px;
  position: relative;
  margin-top: 5px;
}
.annotate .design .p-r-15 {
  padding-right: 15px !important;
}
.annotate .design .p-l-15 {
  padding-left: 15px !important;
}
.annotate .design .sort-order-buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 30px;
  height: 24px;
  padding-left: 2px;
}
.annotate .design .sort-order-buttons .fa-long-arrow-up, .annotate .design .sort-order-buttons .fa-long-arrow-down {
  color: #402f2f;
}
.annotate .design .sort-order-buttons .fa-long-arrow-up:hover, .annotate .design .sort-order-buttons .fa-long-arrow-down:hover {
  color: #000;
}
.annotate .design .width-200 {
  width: 200px;
}
.annotate .design .event-dialog.column .analysis-grid .am-rich-grid-table {
  height: calc(100vh - 380px) !important;
}
.annotate .design .am-treeview .manage-table .column-name.selected-node{
  background-color: #007bff!important;
  color: #fff;
  border: 1px solid #005dc2;
  line-height: 22px!important;
  border-radius: 0.25rem;
  transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
}
.annotate .design .job-list-wrapper .btn-wrapper {
  border-bottom: 1px solid #ccc;
  padding: 2px;
}
.annotate .design .job-list-wrapper .btn-wrapper:last-child {
  border: 0;
}
.annotate .design.tlf-viewer .job-list-wrapper .rich-grid .am-rich-grid-table{
  height: calc(100vh - 130px) !important;
}
.annotate .design span.variable-title {
  padding-left: 15px;
  width: auto;
}
.k-header .small-classifier-drop {
  width: 90px;
}
.annotate .design .title-footnote .wherecondition {
  width: 46%;
}
.annotate .design .m-l-20 {
  margin-left: 20px;
}
.annotate .design .width-fix-50 {
  width: 50px;
}
.annotate .design .width-fix-60 {
  width: 60px;
}
.annotate .design .width-fix-70 {
  width: 70px;
}
.annotate .design .width-fix-75 {
  width: 75px;
}
.annotate .design .width-fix-85 {
  width: 85px;
}
.annotate .design .height-25 {
  height: 25px;
}
.annotate .design .display-style .k-multiselect {
  margin-left: 10px;
}
.annotate .design .display-style .k-multiselect .k-multiselect-wrap {
  padding: 0;
}
.annotate .design .display-style .col-7.custom {
  flex: 0 0 65%;
  max-width: 65%;
}
.annotate .design .bold {
  font-weight: bold;
}
.annotate .design .row-element .float-left {
  float: none !important;
}
.annotate .design .row-element .row-box {
  border-right: 0;
  border-bottom: 1px solid #786262;
  padding: 2px 5px;
}
.annotate .design .row-element .row-box:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.annotate .design .row-element .row-box:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.annotate .design .m-l-15 {
  margin-left: 15px;
}
.annotate .design .annotate-variable {
  border: 2px solid #ff5f5f;
  padding: 2px 5px;
  background-color: #ddf7ff;
  font-weight: bold;
  font-size: 10px;
}
.annotate .design .header-title {
  padding: 1px 10px 0;
  background-color: #1f44ff;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  line-height: 1;
  vertical-align: bottom;
}
.annotate .design .row-header-title .header-title {
  padding: 1px 3px 0;
}
.annotate .design .title-wrapper {
  line-height: 1.2
}
.annotate .design .block-section, .annotate .design .row-section {
  height: calc(100% - 15px);
}
.annotate .design .small-dropdown {
  width: 112px !important;
}
.annotate .design .small-dropdown ul.k-reset {
  width: 80px;
}
.annotate .design .small-dropdown ul.k-reset li span{
  font-size: 12px;
}
.annotate .design .small-dropdown ul.k-reset li span.k-select{
  margin-left: 5px;
}
.annotate .design .small-dropdown ul.k-reset li.k-button {
  padding:0 3px;
}
body .annotate .design .small-dropdown .k-multiselect:after {
  margin: 5px 3px;
}
.annotate .design .small-dropdown .k-multiselect-wrap {
  padding-right: 5px;
}
.annotate .design .width-40 {
  width: 36px !important;
}
.annotate .design .m-t-3 {
  margin-top: 3px;
}
.annotate .design .width-150 {
  width: 150px !important;
}
.annotate .current-table {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 7px;
}
.annotate .current-table .badge-success {
  font-size: 12px;
}
.annotate .design .treatmen-arm-grid .analysis-grid {
  width: 915px;
}
.annotate .design .treatmen-arm-grid .analysis-grid .am-rich-grid-table th {
  white-space: inherit;
}
.annotate .design .annotate-handler {
  position: absolute;
  top: 3px;
  right: 50px;
  cursor: move;
}
.annotate .design .group-box .annotate-handler, .annotate .design .column-box .annotate-handler {
  top: 1px;
  right: 40%;
}
.annotate .design .row-section .row-box .title{
  color: #ddf7ff !important;
  height: 100%;
  padding: 0 4px;
}
.annotate .design .row-element .row-box .title{
  color: #ddf7ff !important;
  padding: 4px;
}
.annotate .design .row-section .row-box:hover .title, .annotate .design .row-element .row-box:hover .title {
  color: #c9f1fd !important;
}
.annotate .design .row-box .annotate-handler {
  left: auto;
  right: auto;
  top: 10px;
}
.annotate .design .column-table {
  width: 100%;
  table-layout: fixed;
}
.annotate .design .column-table td {
  word-break: break-all;
}
.annotate .design .row-element .row-box {
  position: relative;
}
.annotate .design .row-element .row-box .annotate-handler {
  top: 3px;
}
.annotate .design .column-table.trtgrpt-table tr td {
  border-right: 1px solid;
}
.annotate .design .column-table.trtgrpt-table tr td:last-child {
  border-right: 0;
}
body .annotate .design .event-dialog.block fieldset legend {
  font-size: 11px !important;
}
.annotate .design .custom.p-0.p-l-15 {
  padding-left: 15px !important
}
.annotate .design .annotate-handler.avalue-title {
  right: auto;
  z-index: 1;
  left: 40%;
  top: 57%;
}
.annotate .design .annotate-handler.avalue-label {
  right: auto;
  z-index: 1;
  left: 35%;
  top: 65.5%;
}
.annotate .design .annotate-handler.row-label {
  left: auto;
  right: auto;
  z-index: 1;
  background-color: #fff;
}
.annotate .design .annotate-handler.row-label .handle-row {
  max-width: 105px;
}
.annotate .design .row-label-textarea {
  position: absolute;
  right: 10px;
}
.annotate .design .row-label-textarea textarea {
  border-radius: 5px;
  border: 1px solid #9f9f9e;
  resize: both;
}
.annotate .design .annotate-variable.handle-row, .annotate .design .annotate-handler.row-label input {
  border: 2px solid #000;
  background-color: #ddf7ff;
  font-weight: bold;
  font-size: 10px;
  height: 23px;
}
.annotate .design .annotate-handler .small-btn {
  height: 20px;
  padding: 0;
  width: 20px;
}
.annotate .design .annotate-handler .small-btn span.fa {
  font-size: 11px;
}
.annotate .design .annotate-handler.row-label input {
  margin-bottom: 4px;
}
.annotate .design .annotate-handler.row-label.avalue-title-label {
  left: 102px;
}
.annotate .design .annotate-handler.row-label.avalue-label-label {
  left: 236px;
}
.annotate .design .annotate-handler.visibility-hidden {
  visibility: hidden;
}
.annotate .design .row-box .sort-order-buttons.type-none {
  right: auto;
  margin-left: 5px;
  margin-bottom: 15px;
  width: 20px;
}
.annotate .design .row-box .sort-order-buttons.type-none .fa-long-arrow-left, .annotate .design .row-box .sort-order-buttons.type-none .fa-long-arrow-right {
  height: 5px;
}
.annotate .design .row-box .sort-order-buttons.type-none .moveButton {
  display: block;
  line-height: 0;
}
.annotate .design .row-box .sort-order-buttons.type-none .moveButton .k-button {
  margin-top: 0;
}
.annotate .design .status-message {
  position: absolute;
  right: 29px;
  top: 5px;
}
.annotate .design .status-message .fa-exclamation-triangle {
  background-color: #141313;
  padding: 4px;
  border-radius: 10px;
  font-size: 10px;
}
.annotate .design .overflow-x-auto {
  overflow-x: auto;
}
.annotate .design .block-box .before-parent-table {
  width: 100%;
  table-layout: fixed;
}
.annotate .design .annotate-variable.handle-row {
  height: auto;
}
.annotate .design .row-section.flex-nowrap .row-box {
  width: 105px;
}
.annotate .design .block-identifier-title {
  width: 165px !important;
}
.annotate .design .width-150 {
  width: 150px;
}
.annotate .design .m-r-20 {
  margin-right: 20px;
}
.annotate .design .id-variable-title {
  width: 103px !important;
}
.annotate .design .reporting-pre-where-title, .annotate .design .big-pre-where-title {
  width: 104px !important;
}
.am-rich-grid-table .rgt-cell.row-error {
  background-color: #ff2f2f !important;
}
.annotate .tree-view .rgt-cell-inner img.p_b_2 {
  width: auto;
}
.annotate .move-action-button .am-button {
  display: block;
}
.annotate .regex-columnMaaping-detail .rgt-cell-inner .annotate-contextmenu-expression-dialog table.table {
  text-align: left;
}
.w-90-p {
  width: 90%;
}
.annotate .annotate-contextmenu-applyProcessFlow-dialog .process_flow {
  overflow: hidden;
}
.annotate .annotate-contextmenu-applyProcessFlow-dialog .field_group.bg-white {
  background: #fff;
}
.annotate .annotate-contextmenu-applyProcessFlow-dialog .exView {
  width: calc(100vw - 405px);
  height: calc(100vh - 268px);
}
.annotate .annotate-contextmenu-applyProcessFlow-dialog .no-process-flow {
  height: calc(100vh - 268px);
}
.annotate .remove-mapped-line .am-button .am-icon.x::before {
  content: "\f00d";
}
.annotate .am-rich-grid-table .expression-dialog-button-wrapper .am-form-line-left {
  text-align: left;
}
.annotate .event-dialog .variable-list.am-dropdown .Dropdown-control .Dropdown-placeholder.is-selected {
  width: 200px;
}
.annotate .sort-order-buttons .moveButton .am-primary:hover, .annotate .sort-order-buttons .moveButton .am-primary:active {
  border: initial !important;
  background: initial !important;
  color: initial !important;
  box-shadow: none !important
}
.constructive-operation .rgt-cell-tableTitle .rgt-text-truncate  {
  white-space: initial;
}
.annotate .constructive-operation .tab-content .tab-pane .tab-child > div:first-child {
  position: inherit;
}
.annotate .constructive-operation .grid_not_gby_Mapper-grid .height_fixed .am-rich-grid-table {
  height: calc(100vh - 235px) !important;
}
.annotate .execution-plan .am-rich-grid-table .am-dropdown .Dropdown-control .Dropdown-placeholder.is-selected {
  width: 135px;
}
.am-sheet.Spreadsheet.study tbody th:nth-child(2), .am-sheet.Spreadsheet.datasets tbody th:nth-child(10), .am-sheet.Spreadsheet.variables tbody th:nth-child(4), .am-sheet.Spreadsheet.whereclauses tbody th:nth-child(4), .am-sheet.Spreadsheet.codelists tbody th:nth-child(2), .am-sheet.Spreadsheet.dictionaries tbody th:nth-child(3), .am-sheet.Spreadsheet.methods tbody th:nth-child(6), .am-sheet.Spreadsheet.methods tbody th:nth-child(7) {
  min-width: 150px;
}
.am-sheet.Spreadsheet.study tbody th:nth-child(3), .am-sheet.Spreadsheet.codelists tbody th:nth-child(10), .am-sheet.Spreadsheet.comments tbody th:nth-child(3), .am-sheet.Spreadsheet.documents tbody th:nth-child(3) {
  min-width: 500px;
}
.am-sheet.Spreadsheet.datasets tbody th:nth-child(2), .am-sheet.Spreadsheet.datasets tbody th:nth-child(8), .am-sheet.Spreadsheet.variables tbody th:nth-child(2), .am-sheet.Spreadsheet.variables tbody th:nth-child(9), .am-sheet.Spreadsheet.variables tbody th:nth-child(10), .am-sheet.Spreadsheet.whereclauses tbody th:nth-child(5), .am-sheet.Spreadsheet.codelists tbody th:nth-child(5), .am-sheet.Spreadsheet.methods tbody th:nth-child(8), .am-sheet.Spreadsheet.methods tbody th:nth-child(9) {
  min-width: 80px;
}
.am-sheet.Spreadsheet.datasets tbody th:nth-child(3), .am-sheet.Spreadsheet.variables tbody th:nth-child(11), .am-sheet.Spreadsheet.variables tbody th:nth-child(14), .am-sheet.Spreadsheet.variables tbody th:nth-child(17), .am-sheet.Spreadsheet.valuelevel tbody th:nth-child(12), .am-sheet.Spreadsheet.valuelevel tbody th:nth-child(15), .am-sheet.Spreadsheet.whereclauses tbody th:nth-child(6), .am-sheet.Spreadsheet.codelists tbody th:nth-child(3), .am-sheet.Spreadsheet.methods tbody th:nth-child(2), .am-sheet.Spreadsheet.comments tbody th:nth-child(2), .am-sheet.Spreadsheet.documents tbody th:nth-child(2){
  min-width: 200px;
}
.am-sheet.Spreadsheet.datasets tbody th:nth-child(4), .am-sheet.Spreadsheet.datasets tbody th:nth-child(6), .am-sheet.Spreadsheet.datasets tbody th:nth-child(9), .am-sheet.Spreadsheet.variables tbody th:nth-child(12), .am-sheet.Spreadsheet.variables tbody th:nth-child(15), .am-sheet.Spreadsheet.valuelevel tbody th:nth-child(13), .am-sheet.Spreadsheet.valuelevel tbody th:nth-child(16), .am-sheet.Spreadsheet.dictionaries tbody th:nth-child(2), .am-sheet.Spreadsheet.dictionaries tbody th:nth-child(4), .am-sheet.Spreadsheet.dictionaries tbody th:nth-child(5), .am-sheet.Spreadsheet.dictionaries tbody th:nth-child(6), .am-sheet.Spreadsheet.methods tbody th:nth-child(4){
  min-width: 100px;
}
.am-sheet.Spreadsheet.datasets tbody th:nth-child(5), .am-sheet.Spreadsheet.datasets tbody th:nth-child(7), .am-sheet.Spreadsheet.variables tbody th:nth-child(5), .am-sheet.Spreadsheet.codelists tbody th:nth-child(7), .am-sheet.Spreadsheet.methods tbody th:nth-child(3){
  min-width: 300px;
}
.am-sheet.Spreadsheet.variables tbody th:nth-child(8), .am-sheet.Spreadsheet.valuelevel tbody th:nth-child(9), .am-sheet.Spreadsheet.codelists tbody th:nth-child(4), .am-sheet.Spreadsheet.codelists tbody th:nth-child(8) {
  min-width: 120px;
}
.am-sheet.Spreadsheet.variables tbody th:nth-child(16), .am-sheet.Spreadsheet.valuelevel tbody th:nth-child(4),.am-sheet.Spreadsheet.valuelevel tbody th:nth-child(17), .am-sheet.Spreadsheet.documents tbody th:nth-child(4) {
  min-width: 160px;
}
.am-sheet.Spreadsheet.variables tbody th:nth-child(18) {
  min-width: 600px;
}
.am-sheet.Spreadsheet.valuelevel tbody th:nth-child(2) {
  min-width: 80px;
}
.am-sheet.Spreadsheet.valuelevel tbody th:nth-child(5), .am-sheet.Spreadsheet.whereclauses tbody th:nth-child(2), .am-sheet.Spreadsheet.codelists tbody th:nth-child(9){
  min-width: 350px;
}
.am-sheet.Spreadsheet.valuelevel tbody th:nth-child(6) {
  min-width: 250px;
}
.am-sheet.Spreadsheet.methods tbody th:nth-child(5) {
  min-width: 400px;
}
.am-sheet.Spreadsheet.comments tbody th:nth-child(4), .am-sheet.Spreadsheet.comments tbody th:nth-child(5) {
  min-width: 130px;
}
.annotate .custom_save_pop .ui-dialog .ui-dialog-titlebar .action-items .icon.icon-close{
  padding-left: 3px;
}
.annotate .annotate-contextmenu-expression-dialog .expression-detail .table-scroll .table-name, .annotate .treatmen-arm-grid .am-rich-grid-table .rgt-cell-header .rgt-text-truncate {
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}
.annotate .design .Spreadsheet.am-sheet {
  height: calc(100vh - 215px);
}
.annotate .design .Spreadsheet.am-sheet .orange-column.w-500 {
  width: 500px;
}
.annotate .design .Spreadsheet.am-sheet .orange-column {
  width: 115px;
}
.annotate .design .tab-content {
  padding: 2px 0;
}
.annotate .design .tab-content .definition-grid .analysis-grid .am-rich-grid-table {
  height: calc(100vh - 235px) !important;
}
.annotate .design .code_file .CodeMirror {
  height: 250px;
}
.annotate .design .definition-grid ul.nav.nav-tabs.bottom{
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  flex-flow: row;
  width: 100%;
}
.annotate .design .proc-report-tablelist .navigation-bar-menu .am-treeview>ul {
  overflow: auto;
  display: flex;
  height: calc(100vh - 200px);
}
.m-t-3 {
  margin-top: 3px;
}
.annotate .design .denominator .am-dropdown .Dropdown-control .Dropdown-placeholder.is-selected {
  width: 250px;
}
.annotate .design .am-dropdown.width-300 .Dropdown-placeholder.is-selected {
  width: 270px;
}
.annotate .design .label-pattern {
  color: #fff;
  background-color: #000;
  padding: 2px;
  border-radius: 5px;
}
.annotate .design .result-operation-list {
  position: relative;
}
.annotate .design .result-operation-list .add-operation {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.display-inline {
  display: inline !important;
}
.wp-100.am-dropdown .Dropdown-control .Dropdown-placeholder.is-selected {
  width: 100%;
}
.annotate .job-list.am-multi-select.rmsc .options {
  max-height: 65px;
}
.annotate .apply-process-flow-constructive .rgt-cell .old-title {
  font-size: 10px;
  text-decoration: line-through;
  color: red;
  margin-left: 5px;
}
.annotate .apply-process-flow-constructive .rgt-cell ul.variable-list {
  padding-left: 5px;
  margin-bottom: 0;
  width: 100%;
}
.annotate .apply-process-flow-constructive .rgt-cell li.variable-title {
  display: block;
  border-bottom: 1px solid #ccc;
  padding: 2px 0;
}
.annotate .apply-process-flow-constructive .rgt-cell ul.variable-list li:last-child {
  border-bottom: 0;
  margin-bottom: 1px;
}
.annotate .target-source-variable-expression-list .ui-dialog .ui-dialog-buttonpane {
  justify-content: center;
}
.width-87 {
  width: 87px !important;
}
.width-120 {
  width: 120px !important;
}
