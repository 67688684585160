#react-confirm-alert .react-confirm-alert-overlay {
  z-index: 10001;
  background: #00000080;
  animation: none;
  opacity: 1;
}

#react-confirm-alert .react-confirm-alert-body {
  padding: 0;
  width: auto;
  border-radius: 0.25rem;
}
#react-confirm-alert .react-confirm-alert-body h1{
  padding: 6px 5px;
  background-color: #e4e7eb;
  border: 0;
  font-weight: 700;
  color: #212529;
  background-clip: padding-box;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  font-size: .75rem;
  line-height: 1.5;
  font-family: Verdana, 'Segoe UI', Tahoma, Geneva, sans-serif !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  margin-bottom: 0;
}
#react-confirm-alert .react-confirm-alert-body .message {
  padding: 1rem;
  border: 0;
  color: #212529;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
  padding-top: 5px;
  padding-bottom: 0;
  min-height: 31px;
  font-size: .75rem;
  font-family: Verdana, 'Segoe UI', Tahoma, Geneva, sans-serif !important;
}
#react-confirm-alert .react-confirm-alert-body .button-group {
  margin: 0;

    list-style: none;
    outline: 0;
    white-space: nowrap;
    display: inline-flex;
    vertical-align: middle;
    position: relative;
  height: 55px;
  border-color: rgba(33,37,41,.125);
  padding: 1rem;
  justify-content: flex-end;
  border-width: 1px 0 0;
  border-style: solid;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex: 0 0 auto;
  border-bottom-left-radius: 0.25rem;
}
#react-confirm-alert .react-confirm-alert-body .button-group .am-button~.am-button {
  margin-left: 0.5rem;
}
#react-confirm-alert .react-confirm-alert-body .button-group .am-button {
  min-width: 80px;
}
#react-confirm-alert .react-confirm-alert-body .button-group .am-button.m-w-40 {
  min-width: 40px;
}
