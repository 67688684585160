#crumbs ul {
  list-style: none;
  display: inline-table;
  margin: 0;
  padding: 0;
}
#crumbs ul li {
  display: inline;
}
#crumbs ul li span {
  display: block;
  float: left;
  height: 22px;
  background: #e4e7eb;
  text-align: center;
  padding: 2px 20px 0 20px;
  position: relative;
  margin: 5px 8px 0 0;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
}
#crumbs ul li span:after {
  content: "";
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 11px solid #e4e7eb;
  position: absolute;
  right: -11px;
  top: 0;
}
#crumbs ul li span:before {
  content: "";
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 11px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
}

#crumbs ul li:first-child span {
  padding-left: 12px;
}

#crumbs ul li:first-child span:before {
  display: none;
}

#crumbs ul li:last-child span {
  padding-right: 10px;
}

#crumbs ul li span:hover {
  background: #007bff;
  color: #fff;
}

#crumbs ul li span:hover:after {
  border-left-color: #007bff;
  color: #fff;
}
#crumbs ul li span i {
  margin-right: 5px;
  font-size: 10px;
}
