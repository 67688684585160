/*** button start ***/
.am-button {
  transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 1rem;
  line-height: 1.5;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  position: relative;
  border-color: #e4e7eb;
  color: #212529;
  background-color: #e4e7eb;
}
.am-button.am-primary {
  border-color: #007bff;
  color: #fff;
  background-color: #007bff;
}
.am-primary:hover, .am-button.am-primary:hover {
  border-color: #0062cc;
    color: #fff;
    background-color: #0069d9;
}
.am-button.am-primary:focus {
  box-shadow: 0 0 0 3px rgb(0 123 255 / 50%)
}
.am-button.am-primary:active {
  border-color: #005cbf !important;
  color: #fff !important;
  background-color: #0062cc !important;
}
.am-button:active {
  border-color: #bfc6d0;
    color: #212529;
    background-color: #c7cdd5;
}
.am-button:hover {
   border-color: #c7cdd5;
   color: #212529;
   background-color: #ced3db;
}
.am-button:focus {
    box-shadow: 0 0 0 3px rgb(228 231 235 / 50%);
    text-decoration: none;
    outline: 0;
}
.am-button .am-icon {
  color: inherit;
    align-self: center;
    position: relative;
}
.am-icon, .ui-dialog .ui-dialog-titlebar .action-items .icon {
  width: 1em;
  height: 1em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-family: "FontAwesome";
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  speak: none;
  text-transform: none;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
}
.am-icon:before, .ui-dialog .ui-dialog-titlebar .action-items .icon.icon-close:before {
  vertical-align: baseline;
}
.am-button.disabled {
  pointer-events: none;
}
.ui-dialog-buttonpane .am-button.disabled{
  border-color: #e4e7eb;
  color: #212529;
  background-color: #e4e7eb;
}
/*** button end ***/

/*** button start ***/
.am-textbox, .am-rich-grid-table .rgt-search-container .rgt-search-input {
  transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  width: 12.4em;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font: inherit;
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  -webkit-appearance: none;
  border-color: rgba(33,37,41,.15);
  color: #212529;
  background-color: #fff;
}
.am-textbox:focus, .am-rich-grid-table .rgt-search-container .rgt-search-input:focus {
  border-color: #80bdff;
  box-shadow: none;
}
.am-textbox:disabled, .am-textbox[disabled] {
  outline: none;
  cursor: default;
  opacity: .65;
  -webkit-filter: grayscale(.1);
  filter: grayscale(.1);
  pointer-events: none;
  box-shadow: none;
}
/*** button end ***/

/*** dropdown start ***/
.am-dropdown {
  border-width: 0;
  background-color: transparent;
  line-height: 1.5;
  text-align: left;
  white-space: nowrap;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  border-color: rgba(33,37,41,.125);
  color: #212529;
  width: 12.4em;
}
.am-dropdown .Dropdown-control {
  width: 100%;
  transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
  border-color: #e4e7eb;
  color: #212529;
  background-color: #e4e7eb;
  border-width: 0;
  height: 26px;
  outline: 0;
  cursor: pointer;
  border-radius: .25rem;
  border-style: solid;
  box-sizing: border-box;
  position: relative;
  line-height: 1.5;
  text-align: left;
  white-space: nowrap;
  padding-top: 4px;
  padding-left: 6px;
}
.am-dropdown.Dropdown-root .Dropdown-control.Dropdown-disabled {
  opacity: 0.6;
  filter: grayscale(.1);
  pointer-events: none;
  box-shadow: none;
}
.am-dropdown .Dropdown-control .Dropdown-arrow {
  border-color: #212529 transparent transparent;
  top: 11px;
}
.am-dropdown.is-open .Dropdown-control .Dropdown-arrow {
  border-color: transparent transparent #212529;
}
.am-dropdown .Dropdown-menu {
  font-size: .75rem!important;
  z-index: 1003;
  overflow: auto;
  outline: 0;
  font: inherit;
  text-decoration: none;
  list-style: none;
  border-radius: 0.25rem;
  margin: 0.125rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0.5rem 0;
  border-width: 1px;
  border-style: solid;
  line-height: 1.5;
  box-sizing: content-box;
  border-color: rgba(33,37,41,.15);
  color: #212529;
  background-color: #fff;
  width: auto;
  left: auto;
  top:auto;
  direction:ltr;
  position: fixed;
  margin-top: 27px;
  display: none;
}
.am-dropdown .Dropdown-menu .Dropdown-option {
  color: inherit;
  transition: all .2s ease;
  min-height: 1.5em;
  line-height: 1.5em;
  white-space: normal;
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  outline: none;
  font-size: .75rem;
  padding: 2px 5px!important;
}
.am-dropdown .Dropdown-menu .Dropdown-option.is-selected {
  box-shadow: inset 0 0 0 2px hsla(0,0%,58%,.15)!important;
  background-color: #007bff!important;
  color: #fff;
}
.am-dropdown.auto-height .Dropdown-menu .Dropdown-option {
  min-height: auto;
}
.am-dropdown .Dropdown-menu .Dropdown-option.blank-option {
  min-height: 1.5em;
}
.am-dropdown.is-open .Dropdown-control {
  box-shadow: 0 0 0 3px rgb(228 231 235 / 50%);
}
.am-dropdown.top-3 .Dropdown-menu {
  margin-top: -89px;
}
.am-dropdown.top-2 .Dropdown-menu {
  margin-top: -65px;
}
.export-multiselect .am-multi-select {
  width: 100%;
}
.am-dropdown.top-3 .Dropdown-menu {
margin-top: -90px;
}
.am-dropdown .Dropdown-control .Dropdown-placeholder.is-selected {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 128px;
}
/*** dropdown end ***/

/*** multiple select start ***/
.am-multi-select {
  font-size: .75rem;
  border-radius: 0.25rem;
  line-height: 1.5;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  border-color: #21252920;
  display: inline-flex;
}
.am-multi-select .dropdown-container[aria-disabled="true"] {
  outline: none;
  cursor: default;
  opacity: .65;
  pointer-events: none;
  box-shadow: none
}
body .am-multi-select .dropdown-container {
  background-color: inherit;
  border: 0;
  width: 100%;
}
body .am-multi-select * {
  transition: none;
}

body .am-multi-select .dropdown-container .dropdown-heading {
  height: 26px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.am-multi-select .dropdown-container svg {
  position: inherit;
}
.am-multi-select .dropdown-container svg.dropdown-heading-dropdown-arrow {
  position: absolute;
  right: 5px;
}
body .am-multi-select.rmsc .dropdown-container:focus-within {
  box-shadow: 0 0 0 3px rgb(228 231 235 / 50%);
  border-color: #21252920;
}
body .am-multi-select.rmsc .gray {
  color: #212529;
}
body .am-multi-select.rmsc .dropdown-heading .dropdown-heading-value {
  flex: inherit;
  padding-right: 5px;
}
body .am-multi-select .dropdown-container .dropdown-content {
  position: fixed;
  top: auto;
  width: auto;
  padding-top: 5px;
  display: none;
  margin-left: 2px;
  z-index: 2;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body .am-multi-select .dropdown-container .dropdown-content .item-renderer  input[type="checkbox"] {
  display: none;
}
body .am-multi-select .dropdown-container .dropdown-content ul{
  padding-left: 0px;
  max-height: 180px;
  overflow-y: auto;
}
.annotate .page-options .col-2 .pivot-order-dialog .am-multi-select .dropdown-container .dropdown-content ul label {
  height: auto !important;
}
body .am-multi-select .dropdown-container .dropdown-content .select-item {
  color: #212529;
  padding: 5px;
  margin: 0px;
  background: inherit;
  padding-bottom: 0px;
}
body .am-multi-select .dropdown-container .dropdown-content .select-item.selected {
    background-color: #007bff !important;
    color: #fff !important;
}
body .am-multi-select .dropdown-container .dropdown-content li:last-child .select-item {
  padding-bottom: 5px;
}
body .am-multi-select .dropdown-container .dropdown-content .select-item:hover {
  color: #16181b;
  background-color: #f2f2f2;
}
body .am-multi-select .dropdown-container .dropdown-content .select-item:focus-visible {
  box-shadow: inset 0 0 0 2px hsla(0,0%,58%,.15)!important;
  border: 0;
  outline-offset: 0;
}
body .am-multi-select .dropdown-container .dropdown-content .select-panel .search input{
  height: 26px;
  background: inherit;
}
/*** multiple select end ***/

/** combox start **/
.am-combobox div svg {
  position: inherit;
}
.am-combobox div span {
  width: 0;
}
.am-combobox div span+div, .am-combobox div span+div {
  border-color: #e4e7eb;
  color: #212529;
  background-color: #e4e7eb;
}
.am-combobox div div:nth-child(2) div {
  padding: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.am-combobox span+span+div div:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0;
}
.am-combobox span+span+div {
  min-height: 26px;
  border: 1px solid rgba(33,37,41,.15);
  border-radius: 4px;
  box-shadow: none;
}
.am-combobox {
  border-width: 1px;
}
.am-combobox span+span+div div:nth-child(1) div {
  padding: 0;
  margin: 0;
  cursor: text;
}
.am-combobox span+span+div+div {
  margin-top: 2px;
  z-index: 2;
}
.am-combobox span+span+div+div > div {
  max-height: 200px;
}
.rgt-cell-row .am-combobox span+span+div+div > div {
  max-height: 100px;
}
.am-combobox span+span+div+div > div div {
  padding: 3px 8px;
  color: #212529;
}
.am-combobox .css-1n6sfyn-MenuList div.css-tr4s17-option {
  color: #fff;
}
body .am-combobox {
  padding: 0;
  font-size: .75rem;
  line-height: 1.5;
  flex: none;
  white-space: nowrap;
  vertical-align: middle;
  width: auto;
  height: auto;
}
.am-combobox input{
  border: 1px solid rgba(33,37,41,.15);
  border-radius: 4px;
  height: 28px;
  box-sizing: border-box;
  color: inherit;
  background: none;
  font: inherit;
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px !important;
  background-color: #fff;
}
.am-combobox .wrapper-item-list {
  top: auto !important;
  border-radius: 0.25rem;
  font-size: .75rem;
  border-color: rgba(33,37,41,.15);
  color: #212529;
  background-color: #fff;
}
.am-combobox .wrapper-item-list ul{
  margin: 0;
  padding: 0;
  border-width: 0;
  outline: 0;
  font: inherit;
  text-decoration: none;
  list-style: none;
}
.am-combobox .wrapper-item-list li {
  font-size: .75rem;
  padding: 2px 5px!important;
  border-radius: 0;
  min-height: 1.5em;
  line-height: 1.5em;
  white-space: normal;
  font-weight: normal;
}
/** combox end **/

/**** dialog start ****/
.ui-dialog-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
}
.ui-dialog-overlay {
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    width: 100%;
    height: 100%;
    opacity: .5;
}

.ui-dialog {
    position: fixed;
    outline: 0 none;
    border-color: rgba(33,37,41,.125);
    color: #212529;
    background-color: #fff;
    border-radius: 0.25rem;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    z-index: 10002;
    line-height: 1.5;
    transform: none !important;
    font-size: .75rem;
}

.ui-dialog.maximized{
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.ui-dialog.minimized{
    position: fixed;
    bottom: 0;
    right: 0;
}

.ui-dialog .ui-dialog-titlebar {
    position: relative;
    background-clip:padding-box;
    color: #212529;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
}

.ui-dialog.react-draggable .ui-dialog-titlebar {
    cursor: move;
}
.ui-dialog .ui-dialog-titlebar .action-items {
    margin: calc(-0.375rem - 1px);
    line-height: 1;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    vertical-align: top;
}
.ui-dialog .ui-dialog-titlebar .action-items {
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.ui-dialog .ui-dialog-titlebar .title {
    text-align: center;
    font-size: 16px;
    margin: -0.15em 0;
    font-size: 16px;
    line-height: 1.4;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default;
    flex: 1 1;
}

.ui-dialog .ui-dialog-titlebar .action-items .icon {
  border-color: transparent!important;
  background: none!important;
  box-shadow: none!important;
  transition: color .2s ease-in-out;
  font-size: 14px;
  line-height: 1.3;
  margin: 0 0 0 0.5rem;
  padding: 0;
  border-width: 0;
  opacity: .5;
  width: 20px;
  height: 20px;
  font-size: 17px;
  padding-left: 1px;
  border-radius: 0.25rem;
  cursor: pointer;
}
.ui-dialog .ui-dialog-titlebar .action-items .icon:hover{
  opacity: 0.75
}
.ui-dialog .ui-dialog-titlebar .action-items .icon.icon-close {
  margin-right: 8px;
  background: #000!important;
  color: #fff!important;
  align-self: flex-end;
  padding-left: 3px;
  line-height: 1.2;
}
.custom_save_pop .ui-dialog .ui-dialog-titlebar .action-items .icon.icon-close {
  padding-left: 0;
}
.ui-dialog .ui-dialog-titlebar .action-items .icon.icon-close::before {
  content: "\f00d";
}
.ui-dialog .ui-dialog-titlebar .action-items .icon.icon-minimize {

}

.ui-dialog .ui-dialog-titlebar .action-items .icon.icon-maximize {

}

.ui-dialog .ui-dialog-titlebar .action-items .icon.icon-restore {

}

.ui-dialog .ui-dialog-content {
  padding: 1rem;
  border: 0;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto !important;
  position: relative;
  flex: 1 1 auto;
}
.ui-dialog .ui-dialog-content {
  padding-top: 5px;
  padding-bottom: 0;
  min-height: 31px;
}
.ui-dialog .ui-dialog-buttonpane {
  margin: 0;
  list-style: none;
  outline: 0;
  white-space: nowrap;
  display: inline-flex;
  flex-direction: row;
  vertical-align: middle;
  position: relative;
}
.ui-dialog .ui-dialog-buttonpane {
  border: 0;
}
.ui-dialog .ui-dialog-buttonpane {
    height: 55px;
    border-color: rgba(33,37,41,.125);
    padding: 1rem;
    justify-content: flex-end;
    border-width: 1px 0 0;
    border-style: solid;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    flex: 0 0 auto;
}

.ui-dialog .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}
.ui-dialog-buttonpane .ui-dialog-buttonset .am-button {
  border-radius: 0.25rem;
  flex: 0 0 auto;
  width: auto!important;
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ui-dialog-buttonpane .ui-dialog-buttonset .am-button~.am-button {
  margin-left: 0.5rem;
}
.ui-dialog-buttonpane .ui-dialog-buttonset .am-button:first-child, .ui-dialog-buttonpane .ui-dialog-buttonset .k-group-start {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
/**** dialog end ****/


/**** tabs start ****/
ul.nav.nav-tabs {
  margin: 0;
  padding: 0;
  border-width: 0;
  outline: 0;
  font: inherit;
  text-decoration: none;
  list-style: none;
  width: 100%;
  min-height: auto;
}
ul.nav.nav-tabs {
  border: 0 solid;
  border-color: inherit;
  outline: 0;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  position: relative;
}
ul.nav.nav-tabs {
  border-bottom-width: 1px;
  border-color:rgba(33,37,41,.125);
  color: #212529;
}
ul.nav.nav-tabs li.nav-item {
  border-radius:0;
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-items: stretch;
  cursor: pointer;
  color: #212529;
}
ul.nav.nav-tabs li.nav-item.active {
  color: #007bff;
  border-color: rgba(33,37,41,.125);
  background-color: #fff;
}
ul.nav.nav-tabs li.nav-item.active, ul.nav.nav-tabs li.nav-item.active:hover {
  border-bottom-color:transparent
}
ul.nav.nav-tabs.bottom li.nav-item.active {
  border-top-color: transparent;
  border-bottom-color: inherit;
  margin-top: -1px;
}
ul.nav.nav-tabs li.nav-item .nav-link {
  color: inherit;
  padding: 4px 10px;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  flex: 1 1 auto;
  flex-direction: row;
  align-content: center;
  align-items: center;
  text-decoration: none;
  border: 0;
}
ul.nav.nav-tabs li.nav-item {
  margin-bottom: -1px;
}
ul.nav.nav-tabs li.nav-item:hover {
  border-color: #e9ecef #e9ecef rgba(33,37,41,.125);
}
ul.nav.nav-tabs li.nav-item:hover .nav-link {
  border: 0;
  border-radius: 0;
}
.tab-content {
  border-color: rgba(33,37,41,.125);
  color: #212529;
  background-color: #fff;
  padding: 0.5rem 5px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-width: 0;
  overflow: auto;
  flex: 1 1 auto;
  outline: 0;
  margin: 0;
}
.tab-content .tab-pane {
  font-size: .75rem!important;
  padding: 2px 5px;
  border-radius: 0 0 .25rem .25rem;
  overflow: hidden;
  z-index: 100;
  position: initial;
}

.tab-content .tab-pane .tab-child {
  transition-delay: 0ms;
    position: initial;
}
.sidebar .access-control-wrapper .nav-link:hover {
  color: inherit;
  background: transparent;
}
/**** tabs end ****/

/****** panel start *****/
.am-panelbar {
  border-color: rgba(33,37,41,.125);
  color: #212529;
  background-color: #fff;
  font-size: .75rem;
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  line-height: 1.5;
  list-style: none;
  overflow-anchor: inherit;
}
.am-panelbar .am-panelbar-item {
  color: #212529;
  border: 0 solid;
  display: block;
  cursor: pointer;
  user-select: none;
  transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
  background: none;
  text-decoration: none;
  flex-direction: row;
  align-items: center;
}
.am-panelbar>.am-panelbar-item+.am-panelbar-item {
  border-top-width: 1px;
  border-top-color: #dde2e7;
}
.am-panelbar .am-panelbar-item .accordion-header{
  padding: 4px 10px!important;
  border-color: #dde2e7;
  color: #212529;
  background-color: #f8f9fa;
  font-size: .75rem;
  margin: 0;
  line-height: inherit;
  position: relative;
}
.am-panelbar .am-panelbar-item .accordion-header .accordion-button, .am-panelbar .am-panelbar-item .accordion-header .accordion-button:focus {
  border: 0;
  background-color: transparent;
  width: 100%;
  text-align: left;
  font-weight: 700;
  padding: 0;
}
.am-panelbar .am-panelbar-item .accordion-header .accordion-button:focus {
  outline:inherit;
}
.am-panelbar .am-panelbar-item .accordion-body {
  font-size: .75rem!important;
  padding: 0 5px;
  outline: 0;
  position: relative;
}

.grid-operation .am-panelbar .am-panelbar-item .accordion-header {
  background-color: #e4e7eb;
  border-color: #e4e7eb;
  color: #000;
}
.grid-operation .am-panelbar {
  padding-bottom: 5px;
}
.am-panelbar .am-panelbar-item .accordion-header .accordion-button.collapsed:before, .am-panelbar .am-panelbar-item .accordion-header .accordion-button:before {
  content: "\f0d7";
  font: normal normal normal 16px/1 FontAwesome;
  position: absolute;
  right: 20px;
}
.am-panelbar .am-panelbar-item .accordion-header .accordion-button:before {
  content: "\f0d8";
}
/****** panel end *****/

/***** checkbox start *****/
.am-checkbox, .am-radio {
    margin: 0;
    padding: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    position: absolute;
    opacity: 0;
    -webkit-appearance: none;
    pointer-events: none;
}
.am-checkbox:checked+.am-checkbox-label:before{
  border-color: #007bff;
  background-color: #007bff;
}
.am-checkbox-label:before {
    border-radius: 0.25rem;
}
.am-checkbox-label:before, .am-radio-label:before {
  content: "";
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: solid;
}
.am-checkbox:checked+.am-checkbox-label:after {
    color: #fff;
}
.am-checkbox:checked+.am-checkbox-label:after {
    border-radius: 0.25rem;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.am-checkbox-label:after {
    content: "\f00c";
    width: 16px;
    height: 16px;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.am-checkbox-label:after, .am-checkbox-label:before, .am-radio-label:after, .am-radio-label:before {
    font: normal normal normal 14px/1 FontAwesome;
    box-sizing: border-box;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    left: 0;
    cursor: pointer;
}
.am-radio-label:before {
  border-color: rgba(48,54,60,.15);
  background-color: rgba(48,54,60,.15);
  border-radius: 50%;
}
.am-checkbox-label:before, .am-radio-label:before {
  content: "";
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: solid;
}
.am-radio-label:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  -webkit-transform: scale(0);
  transform: scale(0)
}
.am-radio:checked+.am-radio-label:before {
  background-color: #007bff;
  border-color: transparent;
  box-shadow: inset 0 0 1px 0 #007bff;
}
.am-radio:checked+.am-radio-label:after {
  background-color: #fff;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.am-form-inline .am-checkbox-label, .am-form-inline .am-radio-label, .am-form .am-checkbox-label, .am-form .am-radio-label {
  align-self: center;
  margin-right: 2rem;
}
.am-rich-grid-table .am-checkbox-label, .am-treeview .am-checkbox-label {
  cursor: default;
  outline: 0;
}
.am-form-field .am-checkbox-label{
  padding: 0 !important;
  width: auto !important;
  align-self: center !important;
  padding-left: 20px !important;
}
.am-checkbox-label, .am-radio-label {
  margin: 0;
  padding-left: calc(16px + .5rem);
  min-height: 16px;
  line-height: 17px;
  vertical-align: text-top;
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
}
.am-checkbox:disabled+.am-checkbox-label, .am-radio:disabled+.am-radio-label {
  outline: none;
  cursor: default;
  opacity: .65;
  -webkit-filter: grayscale(.1);
  filter: grayscale(.1);
  pointer-events: none;
  box-shadow: none;
}
.am-checkbox-label:before {
    background-color: rgba(48,54,60,.15);
    border-color: rgba(48,54,60,.15);
}
.am-checkbox:indeterminate+.am-checkbox-label::after {
  border-color: #007bff;
  background-color: #007bff;
  content: "";
  transform: scale(1);
  width: 8px;
  height: 8px;
  top: 4px;
  left: 4px;
}
/***** checkbox end *****/

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
  border: 0;
  background-color: transparent;
}
.alert-dismissible .btn-close:focus, .alert-dismissible .btn-close:hover {
  border: 0;
  background-color: transparent;
}
.alert-dismissible .btn-close:before {
  content: "\f00d";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 20px;
}

.am-treeview {
  padding: 0;
  border-width: 0;
  background: none;
  line-height: 1.5;
  cursor: default;
  overflow: auto;
  white-space: nowrap;
}
.am-form, .am-form-inline {
  line-height: 1.5;
}
.am-form-inline .am-form-field {
  display: flex;
  align-items: flex-start;
  text-align: left;
}
.am-form-inline .am-form-field>label:not(.k-checkbox-label):not(.am-radio-label), .am-form-inline .am-form-field>span:not(.k-widget) {
  width: 25%;
  line-height: 1.5;
  align-self: center;
}
.am-form-inline .am-form-field>label:not(.am-checkbox-label):not(.am-radio-label), .am-form-inline .am-form-field>span:not(.am-widget) {
  padding: calc(.5rem - 1px) 1rem calc(.5rem - 1px) 0;
  text-align: right;
  line-height: 1.5;
  align-self: center;
}
.am-form-inline .am-form-field>input {
  flex: 1 1 auto;
}
.Spreadsheet.am-sheet {
  width: 100%;
  height: calc(100vh - 190px);
  overflow: auto;
  position: relative;
  top: 1px;
  bottom: 0px;
}
.Spreadsheet__table {
  font-family: Arial,Verdana,sans-serif;
  font-weight: 400;
  line-height: 18px;
}
.am-sheet .Spreadsheet__table .Spreadsheet__data-viewer {
  color: #212529;
}
.am-sheet .Spreadsheet__table .Spreadsheet__header, .am-sheet .Spreadsheet__table .Spreadsheet__data-viewer, .am-sheet .Spreadsheet__table .Spreadsheet__data-editor input {
  padding: 1px;
}
.am-sheet .Spreadsheet__table .Spreadsheet__header {
  min-width: 40px;
}
.am-sheet .Spreadsheet__table .Spreadsheet__cell.black-header, .am-sheet .Spreadsheet__table .Spreadsheet__cell.black-header .Spreadsheet__data-viewer {
  text-align: center;
  background-color: #000;
  color: #fff;
}
.am-sheet .Spreadsheet__table .Spreadsheet__cell.orange-column, .am-sheet .Spreadsheet__table .Spreadsheet__cell.orange-column .Spreadsheet__data-viewer {
  color: #000000;
  background-color: #ff9900;
  font-weight: bold;
}
ul.nav.nav-tabs.bottom {
  border-bottom: 0;
  margin-left: 50px;
}
.pivot-grid.row-clickable .drag-and-drop .row-black[data-column-id="2"], .row-clickable .row-black[data-column-id="checkbox"] {
  background-color: #fff !important;
}
/**** temp start ****/

.k-dialog.k-confirm .k-button, .k-dialog.k-prompt .k-button, .study-properties-main .btn.btn-primary, body .k-dialog-buttongroup .k_width_btn {
  min-width: 80px!important
}
/**** temp end ****/
.am-tooltip .tooltip-inner {
  background-color: #4c4cdf;
  box-shadow: 1px 0px 3px #4c4cdf;
  padding: 10px;
  max-width: 400px;
  text-align: left;
}
.am-tooltip .tooltip-inner .tooltip-item {
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.am-tooltip .tooltip-inner .tooltip-item:last-child {
  border: 0;
  padding: 0;
  margin: 0;
}
.f-w-600 {
  font-weight: 600
}
.checkbox-label-m {
  margin-top: 4px;
  margin-bottom: 0 !important
}
