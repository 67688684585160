.margins-cancel{
    margin-left: 5px;
}
.study .k-detail-cell .k-grid-header .k-header .am-checkbox-label {
  visibility: hidden;
}
.project-selection {
  cursor: pointer;
}
.am-rich-grid-table .study-grid.grid-column-3 {
  grid-column: 1 / span 4;
  grid-column-start: 1;
}
.add_study_pro .am-rich-grid-table .am-rich-grid-table {
  height: auto !important;
}
.am-rich-grid-table .study-grid.grid-column-3 .rgt-container .rgt-cell-header-checkbox .rgt-cell-header-inner .am-checkbox-label {
  display: none;
}
.add_study_pro .am-rich-grid-table .rgt-cell .rgt-cell-inner {
  padding-top: 11px;
  padding-bottom: 11px;
}
.add_study_pro .am-rich-grid-table {
  height: calc(100vh - 145px) !important;
}
.add_study_pro .am-rich-grid-table .study-grid.grid-column-3 .rgt-cell .rgt-cell-inner {
  padding-top: 5px;
  padding-bottom: 5px;
}
.add_study_pro .am-rich-grid-table .rgt-cell-header-checkbox .am-checkbox-label {
  margin-left: 2px;
}
.add_study_pro .am-rich-grid-table .rgt-cell {
  height: 41px;
}
.add_study_pro .am-rich-grid-table .study-grid .rgt-cell {
  height: 25px;
}
.add_study_pro .am-rich-grid-table .study-grid .am-rich-grid-table .rgt-container {
  overflow: hidden !important;
}
.add_study_pro .am-rich-grid-table .rgt-container {
  flex: initial !important;
}
.add_study_pro .am-rich-grid-table .am-icon {
  margin-left: 3px;
  font-size: 14px;
}
.add_study_pro .am-rich-grid-table .am-plus::before {
  content: "\f067"
}
.add_study_pro .am-rich-grid-table .am-minus::before {
  content: "\f068"
}
.add_study_pro .am-rich-grid-table .study-grid.grid-column-3 .rgt-cell-studyId .rgt-cell-inner, .add_study_pro .am-rich-grid-table .study-grid.grid-column-3 .rgt-cell-projectId .rgt-cell-inner {
  display: none;
}
.add_study_pro .am-rich-grid-table .study-grid.grid-column-3 .rgt-cell-studyId, .add_study_pro .am-rich-grid-table .study-grid.grid-column-3 .rgt-cell-header-studyId {
  border-left: 0;
}
.add_study_pro .am-rich-grid-table .rgt-row-odd+.study-grid.grid-column-3 .rgt-cell-studyId, .add_study_pro .am-rich-grid-table .rgt-row-odd+.study-grid.grid-column-3 .rgt-cell-header-studyId, .add_study_pro .am-rich-grid-table .rgt-row-odd+.study-grid.grid-column-3 .rgt-cell-projectId, .add_study_pro .am-rich-grid-table .rgt-row-odd+.study-grid.grid-column-3 .rgt-cell-header-projectId {
  background-color: #fff;
}
.add_study_pro .am-rich-grid-table .rgt-row-even+.study-grid.grid-column-3 .rgt-cell-studyId, .add_study_pro .am-rich-grid-table .rgt-row-even+.study-grid.grid-column-3 .rgt-cell-header-studyId, .add_study_pro .am-rich-grid-table .rgt-row-even+.study-grid.grid-column-3 .rgt-cell-projectId, .add_study_pro .am-rich-grid-table .rgt-row-even+.study-grid.grid-column-3 .rgt-cell-header-projectId {
  background-color: rgba(33,37,41,.03);
}
.add_study_pro .am-rich-grid-table .study-grid.grid-column-3 .rgt-cell-header-studyId .rgt-cell-header-inner, .add_study_pro .am-rich-grid-table .study-grid.grid-column-3 .rgt-cell-header-projectId .rgt-cell-header-inner {
  background-color: initial;
}
.add_study_pro .am-rich-grid-table .study-grid.grid-column-3 .am-rich-grid-table{
  border-top: 0;
}
.add_study_pro .am-rich-grid-table .study-grid.grid-column-3 .am-rich-grid-table .rgt-header-container {
  border: 0;
}
.add_study_pro .am-rich-grid-table .study-grid.grid-column-3 .am-rich-grid-table .rgt-cell-header-checkbox, .add_study_pro .am-rich-grid-table .study-grid.grid-column-3 .am-rich-grid-table .rgt-cell-header-displayStudyTitle {
  border-top-width: 1px;
}
.add_study_pro .am-rich-grid-table .rgt-cell.last {
  border-bottom: 0;
}
