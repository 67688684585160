.tag-management .filter-area {
  margin-bottom: 10px;
}
.tag-management .rich-grid .k-grid{
  height: calc(100vh - 370px) !important
}
.tag-management .k-icon.k-i-pencil{
  margin-right: 5px;
}
.study-properties-main .study-properties-dialog .tag-document-dialog .k-widget.k-window.k-dialog, .data-properties .tag-document-dialog .k-widget.k-window.k-dialog {
  width: calc(100% - 40px) !important;
  height: calc(100vh - 40px) !important;
}
.tag-management .m-l-10 {
  margin-left: 10px;
}
.status-blue {
  background-color: blue;
  border-radius: 5px;
}
.tag-document-dialog .navigation-bar-menu .am-treeview .item .column-name {
  color: #ccc;
}
.tag-document-dialog .navigation-bar-menu .am-treeview .item .column-name .column-title {
  color: #212529;
}
.tag-document-dialog .navigation-bar-menu .am-treeview .item .column-name.status-blue .column-title {
  color: #fff;
}
