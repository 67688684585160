.task-management .action-bar {
  text-align: center;
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
}
.task-management .ui-dialog-content .k-dialog{
  width: calc(100% - 10px);
  height: calc(100vh - 20px);
}
.task-management .no-task {
  margin-top: 10px;
  font-size: 26px;
  border: 1px solid #ccc;
  text-align: center;
  color: #73818f;
}
.task-management .action-bar .am-button, .task-management .action-bar .k-datepicker, .task-management .pagination-bar .am-button {
  margin-right: 10px;
}

.task-management .am-rich-grid-table{
  height: calc(100vh - 162px) !important;
}
.task-management .m-r-0 {
  margin-right: 0 !important
}
.create-task-dialog .ui-dialog-content .k-dialog {
  width: calc(100% - 10px);
  height: calc(100vh - 20px);
}
.task-management .m-t-5 {
  margin-top: 5px;
}
.create-task-dialog .am-dropdown {
  width: 100%;
}
.create-task-dialog .full-width {
  width: 100%;
}
.create-task-dialog .jodit-toolbar-button button {
  min-height: auto;
}
.create-task-dialog .jodit-toolbar-editor-collection .jodit-toolbar-button {
  margin: 0 !important;
}
.create-task-dialog .ui-dialog-content .jodit-container {
  height: calc(100vh - 295px) !important;
}
.create-task-dialog span.title {
  font-weight: 600;
}

.task-content .card .card-header {
  text-align: center;
  padding: 3px;
}
.task-content .card.study .card-header {
  background-color: #a68948;
}
.task-content .card.proess-group .card-header {
  background-color: #299b53;
}
.task-content .card.process-flow .card-header {
  background-color: #1e326d;
}
.task-content .card.block .card-header {
  background-color: #212529;
}

.m-r-5 .fa-align-left, .m-r-5 .fa-align-justify {
  margin-right: 5px;
}
.task-list-wrapper .am-button.task-title {
  background: transparent;
  border: 0;
  color: #007bff;
}
.task-list-wrapper .am-button.task-title:hover {
  color: #212529;
}
.buttom-border, .task-detail .comment-wrapper {
  border-bottom: 1px solid #ccc;
}
.task-list-wrapper .title {
  font-weight: 600;
}
.task-detail .comment-wrapper {
  padding-left: 15px;
}
.task-detail .comment-wrapper .footer {
  margin-top: 2px;
  font-style: italic;
}
.task-detail .comment-wrapper .footer .author {
  color: #428bca;
  font-size: 13px;
  margin-right: 5px;
}
.task-detail .comment-wrapper .footer .date {
  font-size: 10px;
  color: #777;
}
.task-detail .comment-wrapper .description {
  color: #5b5b5b;
}
.task-detail .html-preview {
  width: 100%;
  border: 0;
  height: 150px;
}
.task-detail .textarea-box {
  width: 100%;
  height: 110px;
  margin: 0;
  resize: none;
}
.task-detail .comment-content {
  height: calc(100vh - 527px);
  overflow-y: auto;
  min-height: 110px;
}
.create-task-dialog.bulk-action-task-dialog .ui-dialog-content .k-dialog {
  width: 50%;
  height: 300px;
}
.task-detail.list .comment-wrapper {
  padding: 0;
}
.create-task-dialog.edit .ui-dialog-content .jodit-container {
  height: calc(100vh - 430px) !important;
}
.task-management .am-rich-grid-table .rgt-cell-header-inner-checkbox .am-checkbox-label {
  display: none;
}
.create-task-dialog.bulk-action-task-dialog .ui-dialog .ui-dialog-content{
  overflow: hidden !important;
}
.create-task-dialog .ui-dialog .ui-dialog-content {
  overflow-x: hidden !important;
}
.create-task-dialog.edit .ui-dialog .ui-dialog-content {
  overflow-y: hidden !important;
}
