.am-form-line-left{
    margin-left: 1px;
}

label.pro-label {
    background-color: transparent;
}

.text-box-display{
    margin: 0px 0px 5px 27px;
}
.study-properties-main .ui-dialog-container .ui-dialog {
    min-width: 450px;
}
.study-properties-main .export-object .k-widget.k-window.k-dialog {
  min-width: 475px;
}
@media ( max-width: 640px ) {
    .study-properties-main .ui-dialog-container .k-dialog {
        min-width: inherit;
    }
}
.bottom-line {
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
}

.file-progress {
  border: 5px solid #ff6358;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 5px 5px 0 0;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.fa-download.completed {
    font-size: 27px;
    margin: 5px 5px 0 0;
    color: #09b509;
}

.fa-download.fail {
    font-size: 18px;
    margin: 5px 5px 0 0;
    color: red;
}
.fa-download.warning {
    font-size: 18px;
    margin: 5px 5px 0 0;
    color: #dddd24;
}

.main .ui-dialog .ui-dialog-content {
    padding-top: 5px;
    padding-bottom: 0px;
    min-height: 31px;
}

.myAlertInfo-top{
    position: fixed;
    top: 90px;
    right: 2%;
    width: 30%;
}
.myAlertInfo-topS{
    position: fixed;
    top: 90px;
    right: 2%;
    width: 30%;
}
.myAlertWarrning-top{
    position: fixed;
    top: 90px;
    right: 2%;
    width: 30%;
}
.myAlertWarrning-topS{
    position: fixed;
    top: 90px;
    right: 2%;
    width: 30%;
}
.myAlertError-top{
    position: fixed;
    top: 90px;
    right: 2%;
    width: 30%;
}

.alert{
    display: none;
}
.myAlertError-topS{
    position: fixed;
    top: 90px;
    right: 2%;
    width: 30%;
}
/* new tree code */
.custom-menu {
    display: none;
    z-index: 1000;
    position: absolute;
    overflow: hidden;
    border: 1px solid #CCC;
    white-space: nowrap;
    font-family: sans-serif;
    background: #FFF;
    color: #333;
    border-radius: 5px;
    padding:0;
    margin: 0;
}

.custom-menu li {
    padding: 3px 8px;
    cursor: pointer;
    list-style: none;
    min-width: 140px;
}

.custom-menu li:hover {
    background-color: #DEF;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.demo-section.k-content {
    background-color: transparent !important;
}


.am-treeview
{
    padding: 0 !important;
}
body .am-treeview .k-item {
    padding: 0 0 0 11px;
}
body .am-treeview .k-i-collapse, body .am-treeview .k-i-expand {
    margin-left: -14px;
    cursor: pointer;
    width: 14px;
    height: 14px;
}
body .am-treeview .k-in .k-icon, body .am-treeview .k-in .k-image, body .am-treeview .k-in .fa {
    margin-right: .3rem;
}

.fa-circle.completed {
    font-size: 27px;
    margin: 5px 5px 0 0;
    color: #09b509;
}

.fa-circle.fail {
    font-size: 27px;
    margin: 5px 5px 0 0;
    color: red;
}

.fa-exclamation-triangle.yellow{
    color:yellow
}

.fa-exclamation-circle.red{
    color:red
}

.fa-check-circle.green{
    color:green
}
.fa-exclamation-triangle.yellow.status{
    font-size: 21px;
    margin: 5px 0 0 0;
    color:#efef14;
}

.fa-check-circle.green.status{
    font-size: 25px;
    margin: 5px 0 0 0;
    color:green
}
.executealljobs .am-rich-grid-table{
    width: 894px;
    overflow: auto;
}
.fa-exclamation-circle.red.status{
    font-size: 21px;
    margin: 5px 0 0 0;
    color:red;

}

.fa-copy.yellow{
    color:yellow
}

.fa-copy.red{
    color:red
}
.validation_error .k-content.k-window-content.k-dialog-content {
  white-space: break-spaces;
}
.validation_error .fa-times-circle.error {
  color: red;
  font-size: 40px;
  padding-top: 10px;
}

.study-properties-main.importLog .ui-dialog-container .ui-dialog {
  max-height: calc(100vh - 20px);
}
