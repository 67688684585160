.view-contextmenu{
    height: 200px;
    /* overflow: auto; */
}
/* .k-menu-group .k-item>.k-link, .k-menu-vertical .k-item>.k-link {
    line-height: 1;
} */
.k-menu .k-item.hr-view{
    border-bottom: 1px solid #808080;
}

.enable-false{
    display: none !important;
    cursor: default !important;
    opacity: 0.5;
    background: #e5e5e5;
}
.pointer_events{
    pointer-events: none !important;

}
.enable-false .k-link{
    cursor: default !important;

}
/* .enable-false .k-link, .enable-false{
    pointer-events: none !important;

} */
/* .myclasssss ul{
    cursor: default !important;
} */
.custcontext-menu{
    cursor: default !important;
    font-size: 12px !important;
}
.newFontSize
{
    font-size: 28px;
    margin-left: 15px;
    cursor: not-allowed;
    text-decoration: none;
    color: grey;
    padding: 0;

}
.newFontSizeVisible
{
    font-size: 28px;
    margin-left: 15px;
    cursor: pointer;
    padding: 0;
}
.exView{
    overflow:auto;
    overflow-x:auto;
    width: calc(100vw - 232px);
    height: calc(100vh - 167px);
}
.p-r-5 {
  padding-right: 5px;
}
.processflow-scroll .processflow-tab-outer .tabstrip-top > ul {
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  flex-flow: row;
  width: 100%;
}
.am-textbox.blockname:disabled, .am-textbox.blockname[disabled], .am-textbox.blockname.k-state-disabled {
  background-color: #ccc;
}
.m-r-5 {
  margin-right: 5px;
}
.study-properties-main.note .k-widget.k-window.k-dialog {
  width: calc(100% - 10px);
  height: calc(100vh - 20px);
}
.study-properties-main.note .ui-dialog-container .ui-dialog .jodit-container {
  width: calc(100vw - 40px) !important;
  height: calc(100vh - 150px) !important;
}
.processflow-scroll .k-tabstrip>.k-content {
  padding: 4px;
}
.k-tabstrip-top>.k-tabstrip-items:empty {
  border-color: transparent;
}
.single-block-paste .full-width {
  width: 76%;
}
.single-block-paste label {
  background: #fff;
}
.single-block-paste label input[type=radio] {
  margin-right: 5px;
}
