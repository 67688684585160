.pdf-annotate .sidebar {
  overflow: auto;
  color: #212529 !important;
  background: linear-gradient(rgb(249, 248, 247),
      rgb(251, 250, 248) 46px,
      rgb(251, 251, 249) 120px,
      rgb(248, 247, 245) 35%,
      rgb(249, 248, 246));
}

.sidebar__highlights {
  list-style: "none";
  padding: 0;
}

.highlight__location {
  margin-top: 5px;
  text-align: left;
  font-size: 10px;
}

.highlight__image {
  overflow: auto;
  max-width: 300px;
  border: 1px dashed;
}

.sidebar__highlight {
  padding: 5px 10px;
  transition: background 140ms ease-in;
  border-bottom: 1px solid rgb(119, 119, 119);
}

.sidebar__highlight:hover {
  background: rgba(58, 56, 52, 0.08);
}

.pdf-annotate a {
  color: #d35400;
}

.pdf-annotate blockquote {
  padding: 0;
  margin: 0;
  quotes: "\201c""\201d";
}

.pdf-annotate blockquote:before {
  content: open-quote;
}

.pdf-annotate blockquote:after {
  content: close-quote;
}

.Highlight__part {
  position: inherit;
  padding: 8px 0;
}

.Highlight__emoji {
  position: inherit;
}

.annotate-layer {
  position: absolute;
  color: #000;
}

.annotation__item {
  color: #f00;
  background-color: #0ff;
  padding: 0px 5px;
  margin: 0px 5px;
  line-height: 20px;
  height: 20px;
  white-space: nowrap;
  position: absolute;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.annotate-layer .item {
  position: absolute;
  color: #f00;
  background-color: #0ff;
  padding: 5px;
}

.annotation--remove {
  position: absolute;
  width: 5px;
  height: 5px;
  padding: 0 !important;
  right: -2px;
  top: -2px;
}
.sidebar__highlight strong {
  font-size: 10px;
  color: #444444;
}
.sidebar__highlight blockquote {
  font-size: 11px;
}
.pdf-annotate .description {
  padding: 5px 10px;
    overflow: initial;
    line-height: 14px;
    border-bottom: 1px solid;
}
.pdf-annotate .description p{
  margin: 0;
}
.pdf-annotate .control-panel {
  margin: 5px 0;
  overflow: unset
}
.pdf-annotate .control-panel .am-button {
  font-size: 11px;
}
.pdf-annotate .control-panel .am-button.reset, .pdf-annotate .find-annotate .am-button.reset {
  background: #d35400;
  border-color: #d35400;
}
.find-annotate {
  height: 5vh;
  position: absolute;
  top: 0;
  right: 0
}
.PdfHighlighter .pdfViewer .textLayer span mark {
  padding: 0;
  line-height: normal;
}
.pdf-annotate .sidebar .line {
  margin: 5px 0;
}
.sidebar__highlight .highlight__location .page-title {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
  color: #007bff;
}
.sidebar__highlight .highlight__location .page-title:first-child {
  margin-left: 0;
}
.sidebar__highlight .highlight__location .page-title:hover {
  color: #0062cc;
}
.sidebar .custom-switch .k-switch .k-switch-handle > span {
  font-size: 11px;
}
.annotate .sidebar .custom-switch .k-switch {
  width: 43px;
}
.annotate .sidebar .custom-switch .k-switch .k-switch-container {
  line-height: 19px;
}
.annotate .sidebar .custom-switch {
  padding-left: 0;
  display: initial;
}
.annotate .sidebar .custom-switch .k-switch {
  margin: 0;
}
.annotate .sidebar .custom-switch .k-switch .k-switch-handle {
  top: -1px;
}
.annotate .sidebar .custom-switch .k-switch .k-switch-label-on {
  left: -27px;
}
.annotate .sidebar .custom-switch .k-switch .k-switch-label-off {
  left: 15px;
}
.annotate .sidebar .custom-switch .k-switch .k-switch-handle {
  width: 13px;
  height: 13px;
}
.annotate .sidebar .custom-switch .k-switch-label-on, .annotate .sidebar .custom-switch .k-switch-label-off {
  top: -2px;
}
.annotate .sidebar .control-panel .am-button.find-mode {
  background-color: #7aff71;
  border-color:#435242;
  color: #000;
}
.annotate .sidebar ul.sidebar__highlights .sidebar__highlight.find-mode {
  background-color: #7aff71;
  border-color:#435242;
  color: #000;
}
.annotate .sidebar .am-button.k-primary:focus, .annotate .sidebar .am-button.k-primary.k-state-focused {
  box-shadow: none
}
.annotate .PdfHighlighter section.annotation__item div span, .annotate .PdfHighlighter section.annotation__item button.k-button span {
  color: inherit;
  position: inherit;
  cursor: inherit;
}
