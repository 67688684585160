body {
    font-family: Verdana, 'Segoe UI', Tahoma, Geneva, sans-serif !important;
}

.overflowview{
    overflow:auto;
}
.divdiagram {
    position: relative;
    cursor: default;
}
.processflow-tab-outer .tabstrip-top {
    height: calc(100vh - 60px);
    overflow: hidden;
}
.processflow-tab-outer .tabstrip-top>.nav-tabs .nav-item .nav-link .fa-close {
    margin-left: 3px;
}
.process-flow-wrapper .react-flow {
    height: 3000px !important;
    width: 3000px !important;
}
.sidemenu-fileOpen{
    width: 80px;
    border: none !important;
    background: none;
    border-bottom-style: hidden;
}
span.k-sprite.fa.fa-folder-open-o.master-study {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
span.k-sprite.fa.fa-folder-open.master-study {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
span.k-sprite.fa.fa-folder {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
span.k-sprite.fa.fa-connectdevelop {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
span.k-in.fa.fa-table {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
span.k-sprite.fa.fa-table {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
span.k-sprite.fa.fa-database {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
span.k-sprite.fa.fa-object-group {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
span.k-sprite.fa.fa-download {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
span.k-sprite.fa.fa-upload {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
span.k-sprite.fa.fa-line-chart {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
span.k-sprite.fa.fa-bar-chart {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
span.k-sprite.fa.fa-file-pdf-o {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
span.k-sprite.icon-shuffle {
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    overflow: visible;
    font-family: simple-line-icons;
    padding-top: 5%;
}
label{
    /* padding: 5px; */
    background: #f9f9f9;
    display: table;
    color: #656565;
    cursor: pointer;
}
input[type="file"][name="importSource_table"] {
    display: none;
}
input[type="file"][name="importmeta_table"] {
    display: none;
}
input[type="file"][name="import_documents"] {
    display: none;
}
.labelbrowse{
    display: inline-block;
    margin-bottom: auto;
    margin-right: -19px;
    position: relative;
    overflow: hidden;
}
/* tree  scroll*/
.sidebar > div {
    overflow: auto;
}

.breadcrumb{
    display: -ms-flexbox;
    /* display: flex; */
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1.5rem;
    list-style: none;
    background-color: #fff;
    border-radius: 0;
    margin-left: -3%;
    margin-right: -3%;
}
.k-window-title k-dialog-title{
    content: 'Confirmation';
}
.k-confirm .ui-dialog-titlebar::before {
    content: 'Confirmation';
}
.k-confirm .ui-dialog-titlebar .k-dialog-title {
    visibility:collapse;
}

.k-alert .ui-dialog-titlebar::before {
    content: 'Alert';
}
.k-alert .ui-dialog-titlebar .k-dialog-title {
    visibility:collapse;
}

span.pf-checkout {
    font-size: 12px;
}
button.am-button.check-InOut-btn {
    width: 75px;
    height: 30px;
    font-size: 12px;
    margin: 0px 0px 3px 0px;
}
span.total-pf-block {
    font-size: 14px;
}
span.k-window-title.k-dialog-title {
    font-size: 14px;
}
span.k-sprite.fa.fa-briefcase {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
span.k-sprite.fa.fa-medkit {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body .sidebar {
    background: #c8ced3;
}

/*CSS for Checkbox*/
body .am-checkbox-label {
	padding-left: 20px;
    background: transparent;
}
.rich-grid .am-rich-grid-tabletd, .rich-grid .am-rich-grid-tableth {
    padding: 1px;
}
body .am-dropdown, body .k-widget, body .k-input.am-textbox,body .k-textarea,body .am-textbox, body .am-rich-grid-table .rgt-header-container input.rgt-search-input{
	font-size:0.75rem;
}
body .k-input.am-textbox,body .am-textbox, body .k-dateinput-wrap .k-input,body .am-dropdown-wrap .k-input,body .k-multiselect-wrap .k-input,body .k-numeric-wrap .k-input,body .k-picker-wrap .k-input,
body .k-autocomplete .k-input{
	height:26px;
	padding: 0 5px;
}
.k-grid-header table .k-header>.k-link{
	margin: 0;
    padding: 0;
}
.k-grid-header table .k-header>.k-link, .k-grid-header table .k-header{
	font-size: 0.75rem;
    font-weight: 700;
}
table .k-filtercell .k-filtercell-operator>.am-button.k-clear-button-visible, .k-filtercell .am-button-icon {
    height: 26px;
    width: 26px;
    padding: 6px;
}
.k-filtercell-operator .am-dropdown-operator .k-select {
    width: 26px;
    height: 26px;
}
body .k-dateinput-wrap, body .am-dropdown-wrap, body .k-multiselect-wrap, body .k-numeric-wrap, body .k-picker-wrap{
	border-width:0;
	height:26px;
}
body .k-numerictextbox .k-select {
    border-width: 0;
    padding: 0;
	width: 16px;
}
body .k-filtercell .k-numeric-wrap .k-input{
	padding: 0 5px;
	text-indent:0;
}
body .am-checkbox:checked+.am-checkbox-label:after {
    font-size: 12px;
    top: 1px;
    /* color: #000; */
}
/* body .am-checkbox-label:before, body .am-checkbox:checked+.am-checkbox-label:before {
    border-color: #ffd583;
    background-color: #ffd583;
} */
body .am-button{
	padding: 2.5px 12px;
    font-size: 14px;
    line-height: 1.3;
}
body .tab-content{
	font-size: 0.75rem !important;
	padding: 2px 5px;
}
.tab-content{
  border-radius: 0 0 0.25rem 0.25rem;
}
body .k-item .k-animation-container {
    padding: 0;
    cursor: default;
}
body .am-rich-grid-tabletd.k-state-selected, body .am-rich-grid-tableth.k-state-selected, body .am-rich-grid-tabletr.k-state-selected {
    border-color: inherit;
    color: inherit;
}
/*body .k-dialog-wrapper .k-dialog {
    width: calc(100% - 30px);
}
.data-operation .k-dialog-content {
	width: 100% !important;
}*/
body .k-window-title {
	font-size: 16px;
}
body .ui-dialog-titlebar {
    padding: 6px 5px;
    background-color: #e4e7eb;
    border: 0;
    font-weight: 700;
}
.grid-operation .dataViewer .rich-grid .am-rich-grid-table{
    display: flex !important;
}
.dataViewer .k-grid-content {
  -ms-flex: none;
flex: none;
display: block;
-ms-flex: unset;
overflow: scroll;
}
.dataViewer .k-grid-content table {
    table-layout: fixed;
}
.grid-operation .dataViewer .card {
    width: 100%;
    border: 0;
    vertical-align: top;
}
.grid-operation .dataViewer  fieldset{
	float:none;
}
body .process-option .am-form-inline .am-form-field > span, .grid-operation .dataViewer .am-form-inline .am-form-field > span {
    padding: 0;
	width: auto;
	font-size: 0.75rem;
	margin-right:5px;
}
body .process-option .am-form-inline .am-form-field > span > b, .grid-operation .dataViewer .am-form-inline .am-form-field > span > b{
    font-weight: normal;
}
label.am-form-field{
	background:transparent;
}
.processflow-scroll .breadcrumb{
	margin-bottom:5px;
    margin-left: -15px;
    padding: 0.5rem 1rem;
}
.processflow-tab-outer .tabstrip-top {
  font-size: .75rem;
}
.processflow-tab-outer .tabstrip-top>.nav-tabs .nav-item .nav-link {
	padding: .2rem 0.5rem;
}
.splitter .handle-bar .handle-bar_drag {
    cursor: e-resize;
}
.k-list-container li{
	font-size:0.75rem;
	padding: 2px 5px !important;
}
body fieldset legend, body .grid-mapper fieldset legend {
    font-size: 12px !important;
    font-weight: 700 !important;
    position: relative !important;
    margin-top: -16px !important;
    text-align: left !important;
    margin-bottom: 0 !important;
}
body .am-treeview .k-in{
	padding:0;
}
body .app-header .nav-item .nav-link {
    font-size: 0.75rem;
}
body .k-tabstrip>.k-tabstrip-items .k-item .k-link {
	padding: 4px 10px;
}
.k-menu-group .k-item>.k-link, body .k-menu-vertical .k-item>.k-link {
    padding: 3px 0.5rem;
    padding-right: calc(3rem + 16px);
}
.field_group {
    border: 1px solid #e3e4e4;
    border-radius: 6px;
    margin: 5px 0;
    padding: 5px 10px;
    background: #f8f9fa;
}
.field_group .am-form-inline{
    padding: 0;
}
body .m-left{
    margin-left: 10px;
}
body .m-left-20{
    margin-left: 10px;
}
body .m-right{
    margin-right: 10px;
}
body .m_t_5{
    margin-top: 5px;
}
.pop-process-option fieldset, .field_group_main fieldset{
    margin: 0 !important;
    border: 0 !important;
}
.field_group_main{
    background: #f3f3f3;
    padding: 5px 10px;
    border-radius: 6px;
    border: 1px solid #e3e4e4;
    margin: 5px 0;
}
.field_group_main .field_group{
    margin: 6px 0;
}
.field_group_main > fieldset:last-child .field_group:last-child {
    margin-bottom: 0;
}
body .m-0{
    margin: 0 !important;
}
body .p-0{
    padding: 0 !important;
}
.p-r-2{
    padding-right: 5px !important;
}
.p-l-5 {
    padding-left: 5px !important;
}
body .m-r-16{
    margin-right: 16px !important;
}
.field_group h4.title{
    font-weight: normal;
}
body .ui-dialog-titlebar .k-dialog-close {
    margin-right: 8px;
    background: #000 !important;
    color: #fff !important;
    padding: 10px 10px;
}
body .am-checkbox-label, body .am-radio-label{
    background: transparent;
    color: #212529;
}
body .am-form, body .am-form-inline {
    font-size: 0.75rem;
}
.join_master.col-5 {
    -ms-flex: 0 0 47.8%;
    flex: 0 0 47.8%;
    max-width: 47.8%;
}
.join_master.col-1 {
    -ms-flex: 0 0 4.33333%;
    flex: 0 0 4.33333%;
    max-width: 4.33333%;
}
.grid_not_gby_Mapper-grid .join_master.col-5, .join_layout .join_master.col-5{
    -ms-flex: 0 0 41.8%;
    flex: 0 0 41.8%;
    max-width: 41.8%;
}
.grid_not_gby_Mapper-grid .col-1, .join_layout .col-1{
    -ms-flex: 0 0 16.33333%;
    flex: 0 0 16.33333%;
    max-width: 16.33333%;
}
.constructive-operation .grid_not_gby_Mapper-grid .join_master.col-5{
  -ms-flex: 0 0 42%;
  flex: 0 0 42%;
  max-width: 42%;
}
.constructive-operation .grid_not_gby_Mapper-grid .col-1{
  -ms-flex: 0 0 16%;
  flex: 0 0 16%;
  max-width: 16%;
}

body .main .container-fluid{
    padding: 0 5px;
}
body .am-rich-grid-tabletd,body .am-rich-grid-tableth{
    padding: 0;
}
body .am-treeview .k-item{
    padding: 0 0 0 11px;
}
body .am-treeview .k-i-collapse, body .am-treeview .k-i-expand {
    margin-left: -14px;
    cursor: pointer;
    width: 14px;
    height: 14px;
}
.am-treeview .k-sprite {
    margin-top: 4px;
    height: 14px;
}
body .am-treeview .k-in .k-icon, body .am-treeview .k-in .k-image, body .am-treeview .k-in .k-sprite {
    margin-right: .3rem;
}
span.k-combobox {
    border: 1px solid rgba(33,37,41,.15);
    border-radius: 4px;
}
.cust_width{
    width: calc(100% - 15px) !important;
}
.k-grid-header .k-header {
    text-align: center;
}
.k-dialog.k-confirm .am-button, .k-dialog.k-prompt .am-button, .study-properties-main .btn.btn-primary, body .k-dialog-buttongroup .k_width_btn{
    min-width : 80px !important;
}
body .m_5{
    margin-bottom: 5px !important;
}
.am-form-inline .am-dropdown {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.grid_height .am-rich-grid-table{
    height: 410px !important;
}
.align_self{
    align-self: initial !important;
}
.align_self.col-5 {
    -ms-flex: 0 0 45.66667% !important;
    flex: 0 0 45.66667% !important;
    max-width: 45.66667% !important;
}
.p_15{
    padding: 0 15px !important;
}
body .p_l_2{
    padding: 2px !important;
}
body .p_r_5{
    padding: 5px !important;
}
body .p_b_2{
    padding-bottom: 2px !important;
    padding-left: 2px !important;
}
.ui-dialog-content .k-tabstrip>.k-content {
    padding: .5rem 5px;
}
.ui-dialog-content .ui-dialog .ui-dialog-content {
    padding: 0 5px;
}
body .m_l_5{
    margin-left: 5px !important;
}
body .m_r_3{
    margin-left: 3px !important;
}
body .m_b_0{
    margin-bottom: 0 !important;
}
.grid-operation .k-panelbar>.k-item {
    margin-bottom: 5px;
}
.height_fixed .am-rich-grid-table{
    height: calc(100vh - 338px) !important;
}
.height_fixed_pre .am-rich-grid-table{
    height: calc(100vh - 318px) !important;
}
.height_fixed_pre.increase-grid-height .am-rich-grid-table{
    height: calc(100vh - 248px) !important;
}
.height_fixed_tabs .am-rich-grid-table{
    height: calc(100vh - 286px) !important;
}
.height_fixed_process .am-rich-grid-table{
    height: calc(100vh - 350px) !important;
}
.height_fixed_step1 .am-rich-grid-table{
    height: calc(100vh - 262px) !important;
}
.height_fixed_step3 .am-rich-grid-table{
    height: calc(100vh - 340px) !important;
}
.height_fixed_rank .am-rich-grid-table{
    height: calc(100vh - 260px) !important;
}
.height_fixed_ReferencePoint .am-rich-grid-table{
    height: calc(100vh - 402px) !important;
}
.height_fixed_Point_Replace .am-rich-grid-table{
    height: calc(100vh - 438px) !important;
}
.height_fixed_Point_combine .am-rich-grid-table{
    height: calc(100vh - 308px) !important;
}
span.k-icon.k-i-fa.fa-bolt{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.left-grid .k-grid-header table {
    direction: ltr;
}
.left-grid .k-grid-header {
    direction: rtl;
}
.height_fixed_link .height_fixed .am-rich-grid-table{
    height: calc(100vh - 428px) !important;
}
.String_Ops.am-rich-grid-table{
    height: calc(100vh - 175px) !important;
}
.height_fixed_report .am-rich-grid-table{
    height: calc(100vh - 432px) !important;
}
.analysis-grid .am-rich-grid-table{
    height: calc(100vh - 455px) !important;
}
.height_fixed_style_template{
    width : 100%;
}
.height_fixed_style_template .text-editor .CodeMirror{
    height: calc(100vh - 332px);
}
.height_fixed_break .am-rich-grid-table{
    height: calc(100vh - 314px) !important;
}
body .data-operation .custom_save_pop .k-dialog-wrapper .k-dialog {
    height: auto;
    width: auto;
}
.button-panel .custom_save_pop .k-dialog-content {
    width: 100%;
}
body .custom_save_pop .ui-dialog-titlebar .k-dialog-close {
    padding: 2px;
    height: auto;
    line-height: 1;
}
.am-button.k-primary .k-icon {
    top: -1px;
    font-size: 14px;
}
.procerssIcon {
    width: 15px;
    padding-bottom: 1px;
}
.Decollapse_Ops.am-rich-grid-table{
    height: calc(100vh - 260px) !important;
}
.dataViewer.two-grid {
    margin-left: -5px;
    margin-right: -5px;
}
.more-than-two-grid .side_by_side_grid {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
}
.grid-operation .two-grid .side_by_side_grid .card {
    width: 50%;
    padding: 0px 5px;
}
.grid-operation .two-grid.more-than-two-grid .side_by_side_grid .card {
    width: 655px;
}
.grid-operation .two-grid .side_by_side_grid .card:nth-child(2) {
    float: right;
}
.grid-operation .two-grid.more-than-two-grid .side_by_side_grid .card:nth-child(2) {
    float: none;
}
.split_Table .am-rich-grid-table{
    height: calc(100vh - 270px) !important;
}
.ReportingOps_hierarchical_unique .am-rich-grid-table{
    height: calc(100vh - 350px) !important;
}
.reporting_categorical_total .am-rich-grid-table{
    height: calc(100vh - 435px) !important;
}
.reporting_hierarchical .am-rich-grid-table{
    height: calc(100vh - 225px) !important;
}
.more-than-two-grid .height_fixed_pre .am-rich-grid-table{
    height: calc(100vh - 350px) !important;
}
.more-than-two-grid .height_fixed_pre.increase-grid-height .am-rich-grid-table{
    height: calc(100vh - 264px) !important;
}
.bordered_popup{
    border: 1px solid #000;
}
label.am-form-field.ref_Expression {
    margin-top: 30px;
}
body .k-tabstrip .k-item {
    color: #212529;
}
body .k-tabstrip .k-item.k-state-hover, body .k-tabstrip .k-item:hover {
    color: #212529;
}
body .k-tabstrip .k-item.k-state-active{
    color: #007bff;
}
.process_flow svg g text{
    font-size: 8px !important;
    font-weight: 700 !important;
}
body .bordered_popup .k-dialog-wrapper .k-dialog {
    -webkit-box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
}
.search-criteria .k-widget.k-window.k-dialog {
    width: 600px !important;
    height: auto !important;
    -webkit-box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
}

.set_preference_list .k-widget.k-window.k-dialog {
    width: auto !important;
    height: auto !important;
    -webkit-box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 16px -1px rgba(10, 3, 3, 0.75);
    box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
}
.data-operation .rich-grid td.set_preference_list .k-dialog-content, .data-operation .rich-grid td.search-criteria .k-dialog-content{
    overflow: inherit;
}
.set_preference_list .sort-items .list-wrapper{
    min-height: 200px;
}
.set_preference_list .sort-items .button-wrapper{
    margin-bottom: 10px;
}
.grid_not_gby_Mapper-grid .height_fixed .am-rich-grid-table{
    height: calc(100vh - 286px) !important;
}
.CodeMirror {
    height: 480px;
}
.log_Editor .CodeMirror {
    height: calc(100vh - 210px);
    font-size: 12px;
}
.k-menu-group, .k-menu-vertical {
    padding: 0 !important;
}
.Set_PreferenceList .k-dialog-wrapper {
    width: auto;
    height: auto;
    right: 0;
    max-width: 500px;
    margin: 50px auto;
}
.data-operation .rich-grid td.Set_PreferenceList .k-dialog-content{
    width : 100%;
    overflow: hidden;
}
.code_file .CodeMirror {
    height: calc(100vh - 180px);
    font-size: 12px;
}
body .process-option .am-form-inline .am-form-field > .am-combobox{
    width: 290px !important;
    flex: none;
}
body .process-option .am-form-inline .am-form-field.reference-points > .am-combobox{
  width: 275px !important;
}
body .process-option .Reporting_Info .am-form-inline .am-form-field > .am-combobox {
    width: auto !important;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.loader .loader_overlay{
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 99999;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.join_layout .height_fixed .am-rich-grid-table{
    height: calc(100vh - 330px) !important;
}

body .k-multiselect-wrap, body .k-multiselect-wrap:hover, body .am-multi-select, body .k-multiselect.k-state-focused .k-multiselect-wrap{
    color: #212529;
    background-color: #e4e7eb;
    cursor: pointer;
}
body .k-multiselect:after{
  content: "\E006";
  width: 1em;
height: 1em;
-moz-osx-font-smoothing: grayscale;
-webkit-font-smoothing: antialiased;
font-size: 16px;
font-family: WebComponentsIcons;
font-style: normal;
font-variant: normal;
font-weight: 400;
line-height: 1;
speak: none;
text-transform: none;
text-decoration: none;
display: inline-block;
vertical-align: middle;
margin: 5px;
}
body .k-multiselect-wrap > .k-i-close{
  display: none !important  ;
}
body .k-multiselect-wrap .am-button {
    margin: 0;
    padding: 0px 6px;
    min-height: calc(1.5em + .25rem + 1px);
}
.Expression_textarea textarea{
    resize: none;
    height: 100px;
}
.Height_Level_Mapping .am-rich-grid-table{
    height: calc(100vh - 410px) !important;
}
.fa-area-chart{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body .k-grid-header .k-header>.k-link{
    overflow: initial;
    -o-text-overflow: initial;
    text-overflow: initial;
}
body .am-form-inline .am-checkbox-label {
    margin-right: 0;
}
body .am-form-inline .am-checkbox-label.m-r-5 {
  margin-right: 5px;
}
.add_study_pro {
    padding-bottom: 5px;
}
.add_study_pro .k-detail-row td.k-detail-cell {
    padding-right: 10px;
}
/* body .k-grid-header .k-header:nth-child(2)>.k-link:after {
    content: "\f0dc";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 12px;
} */
/* body .k-grid-header .k-header .k-link:after {
    content: "\f0dc";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 12px;
} */
body header .search-bar{
  position: relative;
}
body header .search-bar .am-textbox {
    width: 240px;
    border-radius: 20px;
    padding: 0 30px 0 14px;
}
.app-header .nav-item.search-bar:after {
    content: "\f002";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    position: absolute;
    right: 8px;
    top: 5px;
    color: rgba(33,37,41,.15);
}
.height_fixed_decollapse .height_fixed .am-rich-grid-table{
    height: calc(100vh - 330px) !important;
}
.compare-grid-mapper-height .height_fixed .am-rich-grid-table{
    height: calc(100vh - 324px) !important;
}
.variable-grid-height .height_fixed .am-rich-grid-table{
    height: calc(100vh - 324px) !important;
}
/* .Reporting_Ops .reporting_categorical_total .k-grid, .height_fixed_report .Reporting_Ops .am-rich-grid-table{
    height: calc(100vh - 352px) !important;
}
.Analysis-grid-height .analysis-grid .am-rich-grid-table{
    height: calc(100vh - 396px) !important;
} */
.ReportingOps_CategoricalHierarchical .reporting_categorical_total .am-rich-grid-table, .ReportingOps_CategoricalHierarchical .height_fixed_report .am-rich-grid-table{
    height: calc(100vh - 352px) !important;
}
.ReportingOps_CategoricalHierarchical .analysis-grid .am-rich-grid-table{
    height: calc(100vh - 400px) !important;
}
.ReportingOps_Descriptive .height_fixed_report .am-rich-grid-table{
    height: calc(100vh - 380px) !important;
}
.ReportingOps_Descriptive .analysis-grid .am-rich-grid-table{
    height: calc(100vh - 450px) !important;
}
.ReportingOps_Categorical .analysis-grid .k-grid, .ReportingOps_CategoricalTotalUniquePerId .analysis-grid .k-grid, .ReportingOps_CategoricalTotal .analysis-grid .am-rich-grid-table{
    height: calc(100vh - 480px) !important;
}
.ReportingOps_CategoricalHierarchicalUniquePerId .height_fixed_report .am-rich-grid-table{
    height: calc(100vh - 350px) !important;
}
.ReportingOps_CategoricalHierarchicalUniquePerId .analysis-grid .am-rich-grid-table{
    height: calc(100vh - 398px) !important;
}
.proc-report-title .filter-area, .proc-report-foot-notes .filter-area{
    height: calc(100vh - 310px);
    overflow-y: auto;
}
.proc-report-define .rich-grid .am-rich-grid-table{
    height: calc(100vh - 340px) !important;
}
.proc-report-compute .rich-grid .k-grid, .proc-report-break .rich-grid .am-rich-grid-table{
    height: calc(100vh - 292px) !important;
}
.proc-report-style-template .height_fixed_style_template .text-editor .CodeMirror {
    height: calc(100vh - 306px);
}
body .am-form-inline .report_output_checkbox .am-checkbox-label {
    margin-right: 10px;
}
html body .DaveViewer_height .height_fixed_pre .am-rich-grid-table {
    height: calc(100vh - 230px) !important;
}
html body .DaveViewer_height .height_fixed_pre.increase-grid-height .am-rich-grid-table{
    height: calc(100vh - 149px) !important;
}
.k-widget.k-window.k-dialog.k-alert button.am-button {
    color: white;
    background-color: #007bff;
}
.grid-operation .k-panelbar>.k-item>.k-link, .grid-operation .k-panelbar>.k-item>.k-link.k-state-selected  {
    background-color: #e4e7eb;
    border-color: #e4e7eb;
    color: #000;
}
.grid-operation .k-panelbar>.k-item>.k-link {
    padding: 0px 10px !important;
    text-transform: none;
}
.grid-operation .k-panelbar>.k-item>.k-link.k-state-focused.k-state-selected, .grid-operation .k-panelbar>.k-item>.k-link.k-state-focused:hover, .grid-operation .k-panelbar>.k-item>.k-link.k-state-focused.k-state-hover  {
    -webkit-box-shadow: none;
    box-shadow: none;
}
body .file-explorer .k-widget.k-window.k-dialog {
    height: auto;
    max-width: 310px;
    width: calc(100% - 10px);
}
body .file-explorer .btn-wrapper {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
body .file-explorer .btn-wrapper .am-button.k-primary {
  padding-top: 5px;
  padding-bottom: 5px;
}
body .file-explorer .btn-wrapper .am-button.k-primary .k-icon {
  margin-right: 5px;
}
.DaveViewer_height .k-panelbar .k-group {
    border-width: 0;
}
body .data-operation .Set_PreferenceList .k-dialog-wrapper .k-dialog {
    height: calc(100vh - 118px);
}
.Set_PreferenceList .k-dialog-wrapper:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    background: #000;
    opacity: 0.5;
}
span.k-icon.k-i-fa.fa-arrow-up {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
span.k-icon.k-i-fa.fa-arrow-down {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.Set_PreferenceList button .fa-arrow-up, .Set_PreferenceList button .fa-arrow-down {
    margin-right: 3px;
}
.fileLnk {
    background: transparent;
    border: 0;
    color: #007bff;
}
body .data-operation.code-dialog .k-dialog-wrapper .k-dialog {
    width: calc(100% - 200px);
    height: calc(100vh - 120px);
}
.code-dialog .CodeMirror{
    height: calc(100vh - 172px);
}
.log-dialog .CodeMirror{
    height: calc(100vh - 194px);
}
body .extended-attributes .am-rich-grid-table{
    height: calc(100vh - 126px) !important;
}

.study-properties-main.multiple-copy-paste .k-widget.k-window.k-dialog {
    max-width: 670px;
}
.study-properties-main.importLog .k-widget.k-window.k-dialog {
    min-width: 470px;
    max-height: calc(100vh - 20px);
}
.k-dialog-wrapper .k-dialog-title {
    text-align: center;
}
body .am-rich-grid-tableth.k-state-selected,body .am-rich-grid-tabletd.k-state-selected,body .am-rich-grid-tabletr.k-state-selected>td {
    background-color: transparent;
}
.constructive-operation .am-form-inline .am-form-field>span {
    width: auto;
    text-align: left;
    padding: 0;
    margin-right: 5px;
}
.constructive-operation .input-button-wrapper {
    text-align: center;
    display: inline-block !important;
    width: 100%;
 }
 .alert.show {
    z-index: 99999;
    display: block;
}
.refreshBTN {
    background: transparent;
    border: 0;
}

.executeallproperties .fa.fa-warning {
  font-size: 22px;
}
.constructive-operation + .am-button-group {
    -ms-flex-pack: center;
    justify-content: center;
    padding: 12px;
    height: auto !important;
}
.blockSort .sort-items .list-wrapper {
    max-height: calc(100vh - 250px);
}

.app-header .dropdown.nav-item a {
    margin-right: 10px;
}

.log_Editor.execute_all .CodeMirror {
    height: calc(100vh - 320px);
    font-size: 12px;
}
body .am-rich-grid-tableth {
    border-width: 0 1px 1px 1px;
}
body .am-rich-grid-tabletd {
    border-width: 0 1px 0 1px;
}
body .k-grid-content tr:last-child td, body .k-grid-content-locked tr:last-child td {
    border-bottom-width: 1px;
}
body .app-footer {
    position: fixed;
    bottom: 7px;
    right: 6px;
    background: transparent;
    border: 0;
    padding: 0 5px;
  }
  .study .k-grid-container .k-detail-cell .k-widget {
    margin-left: 28px;
  }
  .study .k-grid-content td.parent-checkbox {
    text-align: center;
  }
  .study .k-grid-content td.parent-checkbox label {
    padding-left: 16px;
  }
  .study .k-grid-container .k-detail-cell>div:empty {
    display: none;
  }
  body .fa-download.completed {
      font-size: 18px;
  }
  .version{
    display:inline;
    margin-left: 6px;
    color:#007bff;
    font-weight: 600;
  }

  .whatsNew:focus{
    outline: none;
  }
  .whatsNew{
    outline: none;
    display:inline;
    margin-left: 10px;
    background: transparent;
    border: 0;
    font-size: 14px;
    color : #007bff;
  }
  .featureListDialog ul {
    padding-left: 20px;
}
  .app-footer .k-dialog {
    width: calc(100% - 530px);
    height: calc(100% - 440px);
}
.featureListDialog {
    height: calc(100vh - 484px);
    overflow: auto;
}

.toolBarButton{
    background: transparent;
    border: 0;
    margin-left:0px !important;
}

.toolBarCheckInButton{
    color:green;
    background: transparent;
    border: 0;
    margin-left:0px !important;
}
.toolBarSyncButton{
    color:black;
    background: transparent;
    border: 0;
    margin-left:0px !important;
}

.executeallproperties .executealljobs .am-rich-grid-table{
    width: calc(100% - 10px);
    height:  calc(100vh - 173px) !important;
}
.executeallproperties.execute-all-node .executealljobs .am-rich-grid-table{
  height:  calc(100vh - 215px) !important;
}
.manage-version.data-properties .k-widget.k-window.k-dialog{
    width: 84%;
    height: calc(100vh - 50px);
}
.executeallproperties .k-widget.k-window.k-dialog {
    width: calc(100% - 10px);
    height: calc(100vh - 30px);
}
.executeallproperties.small-window .ui-dialog-container .ui-dialog {
  width: 750px !important;
}
.manage-version.data-properties .k-widget.k-window.k-dialog .k-widget.k-window.k-dialog {
    width: calc(100% - 10px);
    height: calc(100vh - 30px);
}
body.header-fixed .app-header {
    z-index: 1018;
}
.app-header {
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
   -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
   box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
 }
.col-6:last-child .height_fixed_step1 .am-rich-grid-table{
    height: calc(100vh - 232px) !important;
}
.process-manage-version.data-properties .k-widget.k-window.k-dialog{
    width: 100%;
    max-width: 98%;
}
body .process-option .reference_replace .am-form-inline .am-form-field > .am-combobox{
    width: 470px !important;
    flex: none;
}

.checkedOutUserName {
    text-transform: capitalize;
    display: inline
}
.manage-version .referring-block .k-widget.am-rich-grid-table{
    height: 300px !important;
}
.process-manage-version .referring-block .k-widget.am-rich-grid-table{
    height: 300px !important;
}
.process-manage-version .referring-block.richgrid-height-165 .am-rich-grid-table, .manage-version .referring-block.richgrid-height-165 .am-rich-grid-table, .processflow-scroll .richgrid-height-165 .am-rich-grid-table, .process-manage-version .richgrid-height-165 .am-rich-grid-table{
    height: calc(100vh - 165px) !important;
}
.manage-version .richgrid-height-190 .am-rich-grid-table{
  height: calc(100vh - 190px) !important;
}
.importLog .col-sm-8 span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
}
.data-properties.add-link .k-widget.k-window.k-dialog {
    width: 100%;
    height: auto;
    max-width: 300px;
}

.m-t-10{
    margin-top:10px;
}
.m-b-10{
    margin-bottom:10px;
}
body .spread-sheet-grid .k-spreadsheet {
    height: calc(100vh - 110px);

}

.highLight-back-ground{
    background-color: #007bff !important;
    color: #fff !important;
}
body .am-rich-grid-tabletr.highLight-back-ground>td{
    background-color: #007bff !important;
    color: #fff !important;
}
.study-properties-main .copy-properties .k-widget.k-window.k-dialog {
    max-width: 500px;
}
.compare-flows .data-properties .k-widget.k-window.k-dialog {
    width: calc(100% - 10px) !important;
    height: calc(100vh - 30px) !important;
}

.compare-flows .readOnlydiagram {
    overflow: auto;
    height: calc(100vh - 105px);
}

.error-styled-background { background-color: red; }
.warning-styled-background { background-color: #ff7; }

.notes.text-box-display{
    resize : none !important;
    margin: 0;
    width: 100%;
}

.height-fixed-post-initilization .am-rich-grid-table{
    height: calc(100vh - 200px) !important;
}

.height-fixed-post-initilization .am-rich-grid-table.k-grid-header{
    padding-top:5px !important;
}

.study-properties-main .study-properties-dialog .ui-dialog-container .ui-dialog {
    width: calc(100% - 600px) !important;
}
.study-properties-main .study-properties-dialog.dyanmic-width .k-widget.k-window.k-dialog {
  width: auto !important;
  height: auto !important;
}
.study-properties-main .study-properties-dialog .k-content.k-state-active {
    height: calc(100vh - 313px) !important;
}
.study-properties-main .study-properties-dialog.dyanmic-width .k-content.k-state-active {
  height: auto !important;
  margin-bottom: 15px;
}
.study-properties-main .study-properties-dialog.dyanmic-width .ui-dialog-container .ui-dialog .study-property-content, .study-properties-main .study-properties-dialog.dyanmic-width .ui-dialog-container .ui-dialog .tag-management {
  width: 700px;
}
.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}
.custom-switch {
  padding: 0 !important;
  background-color: transparent;
}
.custom-switch span {
  margin-right: 5px;
  font-weight: 600;
  vertical-align: super;
}
.custom-switch .k-switch {
  width: 66px;
  margin-left: 5px;
}
.custom-switch .k-switch .k-switch-handle {
    width: 16px;
    height: 16px;
    top:-1px;
  }
.custom-switch .k-switch-label-on, .custom-switch .k-switch-label-off {
    top: 0;
}
.custom-switch .k-switch .k-switch-container {
    padding: 0;
    line-height: 18px;
}
.custom-switch .k-switch  .k-switch-handle > span {
  line-height: 12px;
}
.custom-switch .k-switch .k-switch-label-on {
      left: -40px;
}
.custom-switch .k-switch .k-switch-label-off {
      left: 20px;
}
.custom-switch .k-switch-handle{
  color: #444549;
  border-color: #444549;
    background-color: #444549;
}
.study-select-label {
    padding-left: 15px !important;
}
.automation-center-label {
    padding-left: 20px !important;
}
.width-40 {
    width: 40vw !important;
}
.k-list .k-item:focus, .k-list .k-item.k-state-focused {
  background-color: yellow;
  box-shadow: inset 0 0 0 2px rgba(148, 148, 148, 0.15) !important;
}
.k-list-scroller .k-list li:first-child.k-state-focused {
  background: none;
}
.k-list .k-item.k-state-selected {
  background-color: #007bff !important;
}
body {
  background-color: #e4e5e6 !important;
}
span.tableName.badge.badge-pill {
  padding-top: 5px;
  padding-bottom: 9px;
}
span.tableName.badge.badge-pill .am-checkbox-label::before {
  background-color: #fff;
}
span.tableName.badge.badge-pill .fa.fa-download {
  margin-top: 4px;
}
span.tableName.badge.badge-pill .fa.fa-upload {
  margin-top: 4px;
}
span.tableName.badge.badge-pill .am-checkbox:checked+.am-checkbox-label::after {
  color: #1d1440;
}
.m-t-7 {
  margin-top: 7px;
}
.m-t-r-4 {
  margin-top: -4px;
}
.no-border {
  border: 0px !important;
}
.app-header .nav-item.admin-select-label {
  margin-left: 35px;
}
.app-header .nav-item.admin-select-label .dropdown-item {
  min-width: 165px;
  text-align: center;
  padding: 0;
}

.app-header .nav-item.admin-select-label .dropdown-item a {
  padding: 7px 20px;
}
.react-contextmenu.blank-contextmenu {
  border: 0;
}
.left-align {
  text-align: left;
}
.k-calendar-container {
  z-index: 99999 !important
}
ul.notes-list {
  list-style: auto;
}

.app .sidebar {
  background-color: #fff;
}
.app .k-tabstrip-top>.k-tabstrip-items .k-item {
  border-radius: 0;
}

body .processflow-tab-outer .am-button {
  font-size: 12px;
}
.app-header ul.navbar-nav li a {
  font-weight: 600;
}
.header-logo {
   width: 200px;
   text-align: center;
   background-color: #e4e5e6;
 }
.main {
  padding-top: 5px;
}
button.am-button, button.am-button:hover, button.am-button:active {
  text-transform: capitalize;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-t-15-5 {
  margin-left: 15px;
  margin-top: 5px;
}
.executeallproperties .executealljobs .k-widget.k-window.k-dialog {
  width: 75%;
  height: auto;
}
.tag-management .tag-document-dialog.annotate .ui-dialog-container .ui-dialog {
  height: calc(100vh - 20px) !important;
  width: calc(100% - 10px) !important;
}
.sample-filter-icon {
  color: red;
  margin-right: 3px;
}
h3.no-table-found {
  font-size: 16px;
  text-align: center;
  color: red;
  margin-top: 15px;
}
.pivot-grid .height_fixed_step1 .am-rich-grid-table {
  height: calc(100vh - 230px) !important;
}
.grid_height.height_fixed .am-rich-grid-table.Continue_Ops {
  height: calc(100vh - 250px) !important;
}
.grid_height.height_fixed .am-rich-grid-table.split_Table {
  height: calc(100vh - 220px) !important;
}
.am-rich-grid-table .compare-flows .data-properties .process_flow .exView {
  width: 100%;
}
.red-button {
  background: red !important;
  border: red !important;
  color: #fff !important;
  padding: 0 7px !important;
}
.red-button:hover {
  background: #ff6363 !important;
  border: #ff6363 !important;
  color: #fff !important;
}
