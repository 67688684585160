/* ::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}
/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */
/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: rgb(202, 202, 202);
} */
/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
/* -------------------------------------------- */

.left-grid .k-grid-header {
  padding: 0px !important;
  padding-left: 12px !important;
}
.left-grid .k-grid-header .k-grid-header-wrap {
  margin-left: 5px;
}
.left-grid .k-grid-container {
  direction: rtl;
}
.left-grid .k-grid-table {
  direction: ltr;
}
body {
  position: relative;
}

svg {
  position: absolute;
}
path.link {
  cursor: default;
}
.grid-mapper .container {
  max-width: 100%;
}


svg:not(.active):not(.ctrl) path.link {
  cursor: pointer;
}

path.link.selected {
  stroke-dasharray: 10,2;
}

path.link.dragline {
  pointer-events: none;
}

path.link.hidden {
  stroke-width: 0;
}

@-webkit-keyframes blink {
  from { background-color: #000; color: #fff; }
  to { background-color: inherit; color: inherit;}
}
@-moz-keyframes blink {
  from { background-color: black; color: #fff; }
  to { background-color: inherit; color: inherit; }
}
@-o-keyframes blink {
  from { background-color: black; color: #fff; }
  to { background-color: inherit; color: inherit; }
}
@keyframes blink {
  from { background-color: black; color: #fff;}
  to { background-color: inherit; color: inherit; }
}
.grid-mapper .blink {
  -webkit-animation: blink 1s infinite; /* Safari 4+ */
  -moz-animation:    blink 1s infinite; /* Fx 5+ */
  -o-animation:      blink 1s infinite; /* Opera 12+ */
  animation:         blink 1s infinite; /* IE 10+ */
}

.am-checkbox-label.status-0::before{
  content: "\f00d" !important;
  border-color: #ff0000;
  background-color: #ff0000;
  color: #ffffff;
}
.am-checkbox-label.status-1::before{
  border-color: #309c0d !important;
  background-color: #309c0d !important;
}
.m-l-14{
    margin-left: 14px !important;
}
.mapping-button-wrapper {
  position: absolute;
  right: 17px;
  z-index: 1;
}
.constructive-operation .input-table-mapping-wrapper .k-dialog-content {
  width: 450px
}
.grid-mapper .row-black td {
  color: #fff !important;
}
.constructive-operation .grid_not_gby_Mapper-grid .left-grid.join_master.col-5 {
  -ms-flex: 0 0 50.8%;
  flex: 0 0 50.8%;
  max-width: 50.8%;
}
.constructive-operation .grid_not_gby_Mapper-grid .col-1 {
  -ms-flex: 0 0 6.5%;
  flex: 0 0 6.5%;
  max-width: 6.5%;
}
.constructive-operation .grid_not_gby_Mapper-grid .join_master.col-5{
  -ms-flex: 0 0 42.7%;
  flex: 0 0 42.7%;
  max-width: 42.7%;
}
/*.apply-process-flow-constructive .constructive-operation .grid_not_gby_Mapper-grid .left-grid.join_master.col-5{
  -ms-flex: 0 0 47%;
flex: 0 0 47%;
max-width: 47%;
}*/
/*.apply-process-flow-constructive .constructive-operation .grid_not_gby_Mapper-grid .col-1 {
  -ms-flex: 0 0 11%;
  flex: 0 0 11%;
  max-width: 11%;
}*/
.constructive-operation .k-grid-table .table-title {
  padding-left: 10px;
}
.grid-mapper-wrapper .left-grid .rgt-wrapper.am-rich-grid-table, .grid-mapper-wrapper .left-gby-grid .rgt-wrapper.am-rich-grid-table {
  overflow: auto;
  direction: rtl;
}

.grid-mapper-wrapper .left-grid ::-webkit-scrollbar {
  width: 8px;
  height: 8px;background-color: #f5f5f5;
}

.grid-mapper-wrapper .left-grid ::-webkit-scrollbar-track {
    background-color: #f5f5f5
}
.grid-mapper-wrapper .left-grid ::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border: 2px solid #d8d8d8;
}
.grid-mapper-wrapper .left-grid .rgt-wrapper.am-rich-grid-table .rgt-container, .grid-mapper-wrapper .left-gby-grid .rgt-wrapper.am-rich-grid-table .rgt-container {
  overflow: initial !important;
  direction: ltr;
}
.grid-column-handle {
  width: 16px;
  height: 16px;
  text-align: center;
  margin-left: 2px;
}
.grid-column-handle .fa {
  font-size: 14px;
}
.constructive-operation .grid_not_gby_Mapper-grid .left-grid.join_master.col-5 .field_group {
  width: 627px;
}
.constructive-operation .grid_not_gby_Mapper-grid .right-grid.join_master.col-5 .field_group {
  float: right;
}
