.react-flow__nodes .react-flow__node-processBlock {
  font-size: 12px;
  background: #fff;
  text-align: center;
  border: 0;
  width: 87px;
  height: 87px;
}
.react-flow__nodes .react-flow__node-processBlock.selected, .react-flow__nodes .react-flow__node-processNote.selected{
  background: #d1dbeb;
  outline: 1px dashed #74bdc5 !important;
  outline-offset: 8px;
}
.react-flow__nodes .react-flow__handle {
  opacity: 0;
}
.react-flow__nodes .react-flow__node-processBlock.selected .react-flow__handle, .react-flow__nodes .react-flow__node-processBlock.hover .react-flow__handle, .react-flow__nodes .react-flow__node-processNote.selected .react-flow__handle, .react-flow__nodes .react-flow__node-processNote.hover .react-flow__handle {
  opacity: 1;
}
.react-flow__nodes .react-flow__node-processBlock .block-wrapper {
  height: 100%;
  border: 1px solid #bb9999;
}
.react-flow__nodes .react-flow__node-processBlock .block-wrapper .header {
  height: 20px;
  text-align: left;
  padding: 1px 2px;
}
.react-flow__nodes .react-flow__node-processBlock .block-wrapper .header .block-status {
  position: absolute;
  right: 2px;
}
.react-flow__nodes .react-flow__node-processBlock .block-wrapper .content {
  border-top: 1px solid;
  height: 50px;
}
.react-flow__nodes .react-flow__node-processBlock .block-wrapper .content .operation-type {
  margin-top: 6px;
  line-height: 1;
  min-height: 8px;
}
.react-flow__nodes .react-flow__node-processBlock .block-wrapper .content .engine-logo {
  margin-top: 1px;
  min-height: 18px;
}
.react-flow__nodes .react-flow__node-processBlock .block-wrapper .content .block-name {
  margin-top: 2px;
}
.react-flow__nodes .react-flow__node-processBlock .block-wrapper .footer {
  height: 15px;
  background-color: #000;
  color: #fff;
  line-height: 1.8;
}
.react-flow__nodes .react-flow__node-processBlock .block-wrapper .text{
  font-size: 8px;
  font-weight: 700;
  font-family: sans-serif;
}
.processflow-scroll .process-flow-wrapper {
  height: calc(100vh - 90px);
  border-right: 0;
}
.processflow-scroll .process-flow-wrapper .react-flow {
  border: 1px solid #ccc;
}
.process-flow-wrapper .react-flow__edge-path, .process-flow-wrapper .react-flow__connection-path {
  stroke: #282828;
  stroke-width: 2;
}

.process-flow-wrapper .react-flow__edge.selected .react-flow__edge-path, .process-flow-wrapper .react-flow__connection-path {
  stroke: #8d90ed;
}
.process-flow-wrapper .block-wrapper .engine-logo, .process-flow-wrapper .block-wrapper .header {
  position: relative;
}
.process-flow-wrapper .block-wrapper .engine-logo .inputblock-arrow {
  position: absolute;
  left: 0;
}
.process-flow-wrapper .block-wrapper .engine-logo .outputblock-arrow {
  position: absolute;
  left: -8px;
  top: -3px;
}
.process-flow-wrapper .block-wrapper .left-pre-where-icon {
  position: absolute;
  left: 2px;
  top: 23px;
}
.process-flow-wrapper .block-wrapper .right-pre-where-icon {
  position: absolute;
  right: 2px;
  top: 23px;
}
.react-flow__nodes .react-flow__node-processBlock .block-wrapper .footer.text {
  line-height: 2;
}
.process-flow-wrapper .block-wrapper .header .common-icon-location, .process-flow-wrapper .block-wrapper .header .reference-point-logo {
  position: absolute;
  right: 20px;
  top: 4px;
}
.process-flow-wrapper .block-wrapper .header .reference-point-logo {
  right: 32px;
  top: 2px;
}
.process-flow-wrapper .block-wrapper .header .sample-filter-icon {
  margin-left: 2px;
  font-size: 15px;
}

.process-flow-wrapper .react-contextmenu {
    min-width: 160px;
    outline: none;
    opacity: 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.process-flow-wrapper .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
    border: 1px solid #c8ced3 !important;
}

.process-flow-wrapper .react-contextmenu-submenu .react-contextmenu.react-contextmenu--visible {
  top: 0 !important;
  z-index: 9999;
}
.process-flow-wrapper .react-contextmenu-submenu .react-contextmenu.react-contextmenu--visible.dynamic-menu {
  overflow-y: auto;
  height: 200px;
}
.process-flow-wrapper .react-contextmenu-item {
    background: #fff;
    color: #373a3c;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    text-align: inherit;
    white-space: nowrap;
    padding-top: 3px;
    padding-bottom: 3px;
}

.process-flow-wrapper .react-contextmenu-item.react-contextmenu-item--active,
.process-flow-wrapper .react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
    border-radius: 0;
}

.process-flow-wrapper .react-contextmenu-item.react-contextmenu-item--disabled,
.process-flow-wrapper .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
}

.process-flow-wrapper .react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 0;
    padding: 0;
}
.process-flow-wrapper .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.process-flow-wrapper .react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.process-flow-wrapper .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.process-flow-wrapper .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "\f0da";
    font: normal normal normal 14px/1 FontAwesome;
    display: inline-block;
    position: absolute;
    right: 7px;
}
.process-flow-wrapper .react-contextmenu .react-contextmenu-item--divider:first-child {
  border: 0;
}
.process-flow-wrapper .note-wrapper {
  width: 140px;
  height: 140px;
  text-align: center;
}
.process-flow-wrapper .note-wrapper .note-text {
  font: 8px sans-serif;
  white-space: pre;
  font-weight: 700!important;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  text-wrap: balance;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}
.process-flow-wrapper .block-wrapper .post-initialize-icon {
  position: absolute;
  right: 5px;
  font-size: 14px;
  top: 3px;
  color: blue;
}
