.jodit-toolbar-editor-collection .jodit-ui-group_size_middle:nth-child(3), .jodit-toolbar-button_tag .jodit-toolbar-button__text {
  display: none !important;
}
tag {
  background-color: #000;
  color: #fff;;
}
.jodit-toolbar-button .jodit-toolbar-button__icon {
  margin-top: -13px;
}
.k-dialog-content .jodit-container .jodit-workplace .jodit-wysiwyg {
  height: 100% !important
}
.jodit-react-container .jodit-ui-search__box .jodit-ui-search__buttons button svg {
  position: inherit;
}
