.navigation-bar-menu .am-treeview ul {
  background-color: transparent;
  font-size: 12px;
  font-family: inherit;
  color: #212529 !important;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;
  padding-left: 12px;
}
.navigation-bar-menu .am-treeview>ul {
  padding-left: 0;
}
.navigation-bar-menu .am-treeview .item {
  cursor: pointer;
  display: inline-block;
  line-height: 22px;
  padding-right: 4px;
}
.navigation-bar-menu .am-treeview .item:hover {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  border-radius: .25rem;
    -webkit-transition: color .2s ease,background-color .2s ease,border-color .2s ease,-webkit-box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,-webkit-box-shadow .2s ease;
    -o-transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease,-webkit-box-shadow .2s ease;
}

.navigation-bar-menu .am-treeview .item, .navigation-bar-menu .am-treeview .loading{
  color: #212529 !important;
}
.navigation-bar-menu .am-treeview polygon {
  fill: #212529 !important;
}
.navigation-bar-menu .am-treeview .triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6.9px;
  border-color: transparent transparent transparent #212529;
  border-style: inset;
   -webkit-transform:rotate(360deg)
}
.navigation-bar-menu .am-treeview .triangle-wrapper {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 8px;
  margin-right: 3px;
  margin-left: 2px;
  height: 10px !important;
  width: 10px !important;
}
.navigation-bar-menu .am-treeview .item .k-state-border-down {
  color: #fff;
  background-color: #007bff;
  border-color: #005dc2;
  border-radius: .25rem;
    -webkit-transition: color .2s ease,background-color .2s ease,border-color .2s ease,-webkit-box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,-webkit-box-shadow .2s ease;
    -o-transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease,-webkit-box-shadow .2s ease;
}
.react-contextmenu div {
    padding: 1px 6px;
    color: #000;
    font-size: 12px;
    cursor: pointer;
}
.react-contextmenu div:hover {
  color: #fff;
  background-color: #007bff;
  cursor: pointer;
  border-radius: .25rem;
    -webkit-transition: color .2s ease,background-color .2s ease,border-color .2s ease,-webkit-box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,-webkit-box-shadow .2s ease;
    -o-transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease,-webkit-box-shadow .2s ease;
}
.react-contextmenu {
    min-width: 110px;
    background: #fff;
    border: 1px solid #c8ced3;
    border-radius: .25rem;
      -webkit-transition: color .2s ease,background-color .2s ease,border-color .2s ease,-webkit-box-shadow .2s ease;
      transition: color .2s ease,background-color .2s ease,border-color .2s ease,-webkit-box-shadow .2s ease;
      -o-transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
      transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
      transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease,-webkit-box-shadow .2s ease;
    z-index: 4;
}
.navigation-bar-menu input.form-control {
    height: 24px;
    font-size: 12px;
    padding-top: 0;
    padding-bottom: 0;
}
.navigation-bar-menu input.form-control:focus {
      border-color: #80bdff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.focusOnContextMenu {
  background-color: #007bff !important;
  border: 1px solid #005dc2;
  line-height: 22px !important;
  border-radius: .25rem;
    -webkit-transition: color .2s ease,background-color .2s ease,border-color .2s ease,-webkit-box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,-webkit-box-shadow .2s ease;
    -o-transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease,-webkit-box-shadow .2s ease;
}

.navigation-bar-menu .am-treeview ul li div:not(.focusOnContextMenu) {
  background: none !important;
}
.navigation-bar-menu .item .react-contextmenu-wrapper .active-process-flow {
  background-color: #007bff !important;
  color: #fff;
  border: 1px solid #005dc2;
  line-height: 22px !important;
  border-radius: .25rem;
    -webkit-transition: color .2s ease,background-color .2s ease,border-color .2s ease,-webkit-box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,-webkit-box-shadow .2s ease;
    -o-transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease;
    transition: color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease,-webkit-box-shadow .2s ease;
}

.navigation-bar-menu .item .react-contextmenu-wrapper .copyIcon {
  margin-left: 3px;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
}

.import-direction .col-sm-4 {
  margin-bottom: 0
}
.import-direction .col-sm-12 {
  margin-top: 0;
  padding-top:0;
}
.navigation-bar-menu .input-group.view-mode {
    padding: 10px;
    color: #444549;
}
.m-l-28 {
    margin-left: 28px;
}
.navigation-bar-menu .textarea-box {
  height: 100px;
  resize: none;
}
.navigation-bar-menu .am-treeview .manage-project .fa-medkit {
  color: #231f20;
}
.navigation-bar-menu .am-treeview .manage-study .fa-briefcase {
  color: #510e08;
}
.navigation-bar-menu .am-treeview .manage-study-by-tag .fa-tag {
  color: #77618e
}
.navigation-bar-menu .am-treeview .manage-study-property .fa-folder-open {
  color: #a68948
}
.navigation-bar-menu .am-treeview .manage-study-documents .fa-file, .study-manager .study-content .fa-file {
  color: #364aab
}
.navigation-bar-menu .am-treeview .manage-study-source-table .fa-table, .study-manager .study-content .fa-table {
  color: #586e84
}
.navigation-bar-menu .am-treeview .manage-study-meta-table .fa-database, .study-manager .study-content .fa-database {
  color: #0d5e83
}
.navigation-bar-menu .am-treeview .manage-study-process-flow-group .fa-object-ungroup, .study-manager .study-content .fa-object-ungroup {
  color: #3b633b
}
.navigation-bar-menu .am-treeview .manage-study-process-flow .fa-object-group, .study-manager .study-content .fa-object-group {
  color: #299b53
}
.navigation-bar-menu .am-treeview .manage-study-process .fa-microchip, .study-manager .study-content .fa-microchip {
  color: #1e326d;
}
.navigation-bar-menu .read-only-icon, .navigation-bar-menu .delegate-only-icon {
  margin-left: 2px;
  font-size: 9px;
  line-height: 7px !important;
  background-color: #000;
}
.m-l-0 {
  margin-left: 0 !important;
}
.navigation-bar-menu .node-cut {
  opacity: 0.5;
}
.study-properties-main .study-width-text {
  width: 68%;
}
.study-properties-main .study-width-text.width-full {
  width: 89.5% !important;
  margin-left: 10px;
}
.copy-properties .study-width-text {
  width: 74%;
  margin-left: 10px;
}
.study-properties-main .study-width-text.type-2 {
  width: 83%;
  margin-left: 0;
}
.study-properties-main .study-width-text.type-3 {
  width: 76%;
}

.study-properties-main .study-width-text.type-4, .study-properties-main .study-width-text.type-6 {
  width: 66%;
  margin-left: 0;
}
.study-properties-main .study-width-text.type-6 {
  width: 75%;
}
.study-properties-main .study-width-text.type-5, .study-properties-main .study-width-text.type-7 {
  width: 58.5%;
}
.study-properties-main .import-study-defination .study-width-text {
  width: 75%;
}
.study-properties-main .import-study-defination .custom-width-full {
  width: 65%;
}
.study-properties-main .import-study-defination .custom-width-full .custom-width-full {
  width: 100%;
}
.study-properties-main .pro-label.col-sm-2.type-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.study-properties-main .pro-label.col-sm-2.type-6 {
  flex: 0 0 25%;
  max-width: 25%;
}
.study-properties-main .k-content.k-window-content.k-dialog-content .k-tabstrip{
  margin-bottom: 10px;
}
.study-properties-main.study-sample-filter .k-widget.k-window.k-dialog {
  width: calc(100% - 10px);
  height: calc(100vh - 20px);
}
.study-properties-main.study-sample-filter .am-rich-grid-table{
  height: calc(100vh - 175px) !important;
}
.width-500 {
  width: 500px;
}
.width-500.am-dropdown .Dropdown-control .Dropdown-placeholder.is-selected {
  width: 470px;
}
.m-t-18 {
  margin-top: 18px;
}
.search-replace .title {
  font-weight: bold;
}
.search-replace .full-height-grid .am-rich-grid-table {
   height: calc(100vh - 230px) !important;
 }
.related-blocks-dialog .m-t-10 {
  margin-top: 10px
}
.search-replace .am-rich-grid-table .link-button > button {
  text-decoration: underline;
  background-color: initial;
  border: 0px;
  text-transform: none;
}
.search-replace .disabled-grid .am-rich-grid-table .rgt-cell {
  background-color: #007bff !important;
  color: #fff;
}
.study-properties-main .flag-type-3 .pro-label.title, .study-properties-main .flag-type-1 .pro-label.title, .study-properties-main .flag-type-4 .pro-label.title, .study-properties-main .flag-type-2 .pro-label.title {
  font-weight: 600;
}
.study-properties-main .flag-type-3 .text-box-display.full-width, .study-properties-main .flag-type-1 .text-box-display.full-width, .study-properties-main .flag-type-4 .text-box-display.full-width {
  margin-left: 10px;
  width: 93%;
}
.study-properties-main .flag-type-1 .text-box-display.full-width {
  width: 85%;
}
.study-properties-main .flag-type-4 .text-box-display.full-width {
  width: 91%;
}
.study-properties-main .flag-type-2 .text-box-display.full-width {
  width: 87%;
}
