.rgt-wrapper.am-rich-grid-table {
  height: 300px;
  min-height: auto;
}
.process-option .am-rich-grid-table .rgt-header-container {
  padding: 5px 0;
}
.am-rich-grid-table .rgt-header-container, .grid-mapper-wrapper .am-rich-grid-table .rgt-header-container {
  padding: 0;
}
.am-rich-grid-table .rgt-search-container {
  padding: 0;
  padding-left: 5px;
  height: 25px;
}
.am-rich-grid-table .rgt-columns-manager-wrapper {
  padding: 0;
}
.am-rich-grid-table .rgt-search-container .rgt-search-icon {
  display: none;
}
.am-rich-grid-table .rgt-search-label {
  background: transparent;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0;
}
.am-rich-grid-table .rgt-header-container input.rgt-search-input {
  padding-left: 5px;
  margin-right: 5px;
}
.am-rich-grid-table .rgt-columns-manager-popover-title, .am-rich-grid-table .rgt-columns-manager-popover-row > label {
  font-size: 12px;
  padding-left: 5px;
}
.am-rich-grid-table .rgt-columns-manager-popover {
  padding-bottom: 0;
}
.am-rich-grid-table .rgt-columns-manager-popover-title {
  border-bottom: 1px solid rgba(33,37,41,.15);
  margin-bottom: 0;
  padding-bottom: 5px;
}
.am-rich-grid-table .rgt-columns-manager-popover-row > label {
  margin-bottom: 0;
  background: transparent;
}
.am-rich-grid-table .rgt-columns-manager-popover-row > input {
  right: 10px;
}
.am-rich-grid-table .rgt-cell-header-inner {
  padding: 1px;
  font-size: 0.75rem;
  font-weight: 700;
  border-color: #dde2e7;
  color: #212529;
  background-color: #f8f9fa;
  border: 0;
  color: #212529;
}
.am-rich-grid-table .rgt-cell-header, .am-rich-grid-table .rgt-cell {
  min-height: auto;
  max-height: auto;
  border: 1px solid rgb(221, 226, 231);
  border-top-width: 0;
  border-bottom-width: 0;
}
.am-rich-grid-table .rgt-cell-header span {
  text-transform: capitalize;
  width: 100%;
  text-align: center;
}
.am-rich-grid-table .rgt-cell {
  align-items: center;
}
.am-rich-grid-table .rgt-cell .rgt-cell-inner {
  padding: 1px;
  margin: 0 1px;
  color: #212529;
}
.am-rich-grid-table .rgt-cell.rgt-row-odd {
  background-color: transparent;
}
.am-rich-grid-table .rgt-cell.rgt-row-even {
  background-color: rgba(33,37,41,.03);
}
.am-rich-grid-table .rgt-cell-checkbox {
  padding: 0;
  padding-top: 2px;
}
.am-rich-grid-table .rgt-row-hover {
  color: #212529;
  background-color: rgba(33,37,41,.065) !important;
}
.am-rich-grid-table .rgt-footer-right-container .rgt-footer-page-size {
  display: none;
}
.am-rich-grid-table .rgt-footer-items-information, .am-rich-grid-table .rgt-footer-right-container {
  padding: 6px 20px
}
.am-rich-grid-table .rgt-cell.last {
  border-bottom: 1px solid #21252920;
}
.referring-block .am-rich-grid-table .rgt-row-selected {
  background-color: #007bff!important;
}
.referring-block .am-rich-grid-table .rgt-row-selected > .rgt-cell-inner {
  color: #fff!important;
}
