.moveButton .am-button {
  padding: 0 !important;
  margin: 0;
  height: 14px;
  width: 7px;
  background: transparent;
  border: none;
  color: black;
  margin-top: -3px;
  margin-left: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.moveButton .am-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.moveButton .am-button.k-primary:hover {
  background-color: transparent;
  border-color: transparent;
  color: #000;
}

.moveButton .am-button span {
  font-size: 12px !important;
}

.moveButton .k-i-x {
  line-height: 1.2;
}

.navigation-bar-menu .am-treeview ul li div {
  background: none !important;
}

.navigation-bar-menu .am-treeview ul li div>div.highlight-background {
  background-color: yellow !important;
}

.annotate .navigation-bar-menu .item.manage-table-column-value-name {
  margin-left: 15px;
  cursor: initial;
}

.manage-source-tables .column-name {
  text-transform: capitalize;
}

.pdf-annotate .navigation-bar-menu .item.manage-table-column-value-name {
  cursor: pointer;
}
.orange {
  color: orange;
}
.parent-blockname {
  background-color: #000;
  color: #fff;
  padding: 1px;
  border-radius: 5px;
}
.searchNode .width-full {
  width: 97% !important
}
 .navigation-bar-menu .am-treeview .item .column-label div{
   display: inline-block;
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
 }
.am-checkbox-label sub.sub-pivot {
  bottom: -10px !important;
  left: 3px;
}
#import_auto_tlf_event_files {
  display: none;
}
