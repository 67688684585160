.align-right {
  text-align: right;
}
.width-250 {
  width: 250px !important;
}
.width-450 {
  width: 450px !important;
}
body div > .m-l-27 {
  margin-left: 27px !important;
}
.access-control-dialog .k-widget.k-window.k-dialog {
  width: 900px;
  height: calc(100vh - 40px);
}
.access-control-wrapper .list-wrapper {
  border: 1px solid transparent;
  border-color: rgba(0,0,0,.08);
  height: calc(100vh - 235px);
  overflow-y: auto;
}

.access-control-wrapper.access .list-wrapper {
  height: calc(100vh - 310px);
}
.access-control-wrapper.access .list-wrapper.inherit-message-note {
  height: calc(100vh - 330px);
}
.access-control-wrapper .list, .access-control-wrapper .sub-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.access-control-wrapper .list li {
    padding: 5px 0;
    cursor: pointer;
    padding-left: 10px;
}
.access-control-wrapper .list li:hover {
  background-color: rgba(0, 123, 255,.25) !important;
}
.access-control-wrapper .list li.selected {
  background-color: rgba(0, 123, 255, .5) !important;
}
.access-control-wrapper .list li.even {
    background-color: rgba(0,0,0,.04);
}

.access-control-wrapper .list .sub-list li:nth-child(2n) {
    background-color: rgba(0,0,0,.04);
}
.p-l-0 {
  padding-left: 0;
}
.p-r-0 {
  padding-right: 0;
}
.m-l-10 {
  margin-left: 10px;
}
.btn-danger {
  color: #fff;
  background-color: #f63c3a;
  border-color: #f63c3a;
}
.user-management input[disabled] {
  background-color: #d3d3d3;
}
.user-management .full-height-grid .am-rich-grid-table{
  height: calc(100vh - 155px) !important;
}
 .access-control-wrapper ul li.disabled, .access-control-wrapper .list li.disabled:hover{
   background-color: #ffff00 !important;
   cursor: initial;
 }
.access-control-wrapper .fa-check-circle.green.status {
  font-size: 17px;
  margin: 0 0 0 5px;
}
.access-control-wrapper .list-wrapper ul li {
  position: relative;
}
.access-control-wrapper .list-wrapper ul li .k-icon {
  position: absolute;
  right: 15px;
}

.access-control-wrapper .list-wrapper ul ul.sub-list {
  max-height: 150px;
  overflow-y: auto;
}

.access-control-wrapper .list-wrapper ul ul.sub-list li{
  padding-left: 25px;
  pointer-events: none;
  opacity: 0.6;
}
.access-control-wrapper .note-wrapper p{
  margin-bottom: 2px;
}
.access-control-wrapper .red-asterisk {
  font-size: 18px;
  margin-left: 2px;
  color: #ff0000;
}
.group-delegate-hidden {
  display: none;
}
.access-control-wrapper .nav{
    min-height: auto;
}
