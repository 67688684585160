.create-mock-tlf-specs-dialog .k-dialog-wrapper .k-dialog {
  width: calc(100% - 10px);
  height: calc(100vh - 20px);
}
.create-mock-tlf-specs-dialog .full-height {
  height: calc(100vh - 90px);
  overflow-y: auto;
}
.create-mock-tlf-specs-dialog .design-wrapper .tab-content .full-height {
  height: calc(100vh - 163px);
}
body .create-mock-tlf-specs-dialog .k-spreadsheet {
  width: 100%;
  height: calc(100vh - 180px);
}
body .create-mock-tlf-specs-dialog .k-spreadsheet-sheets-bar-add  {
  display: none;
}
body .create-mock-tlf-specs-dialog .spread-sheet-grid .k-spreadsheet {
    height: calc(100vh - 110px);
}
.create-mock-tlf-specs-dialog .m-t-10 {
  margin-top: 10px;
}
.create-mock-tlf-specs-dialog .m-l-10 {
  margin-left: 10px;
}
.sidebar .create-mock-tlf-specs-dialog .nav-link:hover {
  background: transparent;
}
