.app-body .sidebar {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -ms-flex-order: unset;
    order: unset;
}
/* .app-body .splitter-layout > .layout-pane-primary {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -ms-flex-order: -1;
    order: -1;
} */
body .app-body .splitter > .pane.primary{
    background:     #c8ced3;
}

/*Dragger CSS*/
.splitter .handle-bar .handle-bar_drag {
    width: 1px;
    height: calc(100vh - 55px);
    border-left: 1px solid rgba(0, 0, 0, 0.0980392);
}
.splitter .handle-bar {
    width: 2px;
    min-width: 2px;
}
@media (min-width: 992px){
    .sidebar-fixed .app-header + .app-body .splitter > .pane.primary {
        height: calc(100vh - 55px);
    }
    .sidebar-fixed .sidebar{
        position: relative;
        z-index: 1019;
        width: 100%;
        height: auto;
    }  
    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer {
        margin-left: 0;
    } 
    /* html:not([dir="rtl"]) .sidebar-lg-show .sidebar{
        margin-left: auto;
    } */
    /* .sidebar-fixed .app-header + .app-body .sidebar{
        height: auto;
    } */
}

@media (min-width: 576px){
    html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer{
        margin-left: 0
    }
}
