.data-properties .ui-dialog-container .ui-dialog {
    width: 75% !important;
    height: auto !important;
}
.data-properties.add-link .ui-dialog-container .ui-dialog {
  width: 300px !important;
}
.data-properties.group .ui-dialog-container .ui-dialog {
  width: 89% !important;
}
.data-properties.flow .ui-dialog-container .ui-dialog {
  width: 100% !important;
}
.data-properties.flow .data-properties .ui-dialog-container .ui-dialog {
  width: 90% !important;
}
.am-form-line-left{
    margin-left: 1px;
}
.pro-label{
    padding: 5px;
    cursor: default;
    background: transparent;
}
.form-textbox{
    height: 32px;
}
span.k-link.k-header.k-state-selected {
    padding: 0px 0px 3px 5px;
}
.k-panelbar>.k-item.k-state-active>.k-link, .k-panelbar>.k-item.k-state-expanded>.k-link {
    color: #656565;
    background-color: #f6f6f6;
    border-color: #f6f6f6;
    padding: 0px 0px 3px 5px;
}
.referring-block .am-rich-grid-table{
    height: 170px !important;
}
.referring-block .k-widget.am-rich-grid-table.k-grid-content {
    overflow-y: auto;
}
.referring-block .k-grid-header table .k-header, .referring-block .rich-grid .am-rich-grid-tabletd {
    width: 125px;
}
.richgrid-height-200 .am-rich-grid-table{
  height: calc(100vh - 200px) !important;
}
.referring-block .filter-area.am-form-inline {
  padding: 0;
}
.richgrid-height-300 .am-rich-grid-table{
  height: calc(100vh - 300px) !important;
}
.data-properties.full-button-panel .ui-dialog-buttonpane .ui-dialog-buttonset {
  width: 100%;
}
.width-300 {
  width: 300px;
}
