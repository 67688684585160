.align-left {
  text-align: left;
}
.align-center {
  text-align: center !important;
}
label.warning {
  margin-right: 10px;
  font-weight: bold;
  background-color: yellow;
  color:#000;
}
label.error {
  margin-right: 10px;
  font-weight: bold;
  background-color:red;
  color:#000;
}
.button-panel {
  text-align: center;
  width: 100%;
}

.am-form fieldset {
  margin-top: 0;
}
.am-form-inline {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.am-form-field {
  margin-bottom: 5px !important;
}
.hidden {
  display: none !important;
}
/* .grid-operation {
  overflow: auto;
} */
.data-operation .k-dialog-content {
  width: 100%;
  min-height: "500px";
}
.data-operation .k-dialog-title {
  text-align: center;
  line-height: inherit;
}
.data-operation .k-state-active {
  height: auto;
}
.grid-operation .card {
    display: inline-block;
    margin-right: 10px;
}

.grid-operation .rich-grid .am-rich-grid-table{
  display: flex !important;
}
.grid-operation .am-form-inline .am-form-field > span {
  text-align: left;
  width: 140px;
}
.process-option .am-form-inline .am-form-field > span {
  text-align: left;
  width: 50px;
}
.process-option .filter-area {
  margin: 10px 0;
}
.gird-status-bar span {
  display: initial !important;
}
.grid-operation fieldset {
  float: left;
}
.grid-operation .am-rich-grid-tabletd, .grid-operation .am-rich-grid-tableth {
  text-overflow: inherit;
  width: 150px;
}
.popup-content {
   padding: 30px;
   color: #787878;
   background-color: #fcf7f8;
   border: 1px solid rgba(0,0,0,.05);
 }
.k-list-container {
  z-index: 10003;
}
.width-full {
  width: 100% !important;
}
.row-highlight{
  background-color: #ffff00;
  color:#000;
}

.k-confirm .k-window-titlebar::before {
    content: 'Confirmation';
}
.k-confirm .k-window-titlebar .k-dialog-title {
    visibility:collapse;
}

.k-alert .k-window-titlebar::before {
    content: 'Alert';
}
.k-alert .k-window-titlebar .k-dialog-title {
    visibility:collapse;
}
.process-option .k-autocomplete input {
  min-width: 125px;
}
.sort-items .list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sort-items .list-wrapper {
  border: 1px solid transparent;
  border-color: rgba(0,0,0,.08);
  max-height: 320px;
  overflow-y: auto;
}
.sort-items .list li {
    padding: 7px 0;
    cursor: pointer;
    padding-left: 10px;
}
.sort-items .list li:hover {
  background-color: rgba(255,99,88,.25) !important;
}
.sort-items .list li.selected {
  background-color: rgba(255,99,88,.50) !important;
}
.sort-items .list li:nth-child(even) {
    background-color: rgba(0,0,0,.04);
}

.sort-items .break-line {
  margin: 5px 0;
}

.sort-items .button-wrapper {
  margin-top: 10px;
}
.button-wrapper .k-primary {
  margin-right: 10px;
}
.am-form fieldset legend {
  text-align: center !important;
}
.small-margin {
  margin-left: 15px;
}
h4.title {
  background-color: #ccc;
  font-size: 14px;
  padding: 5px;
}
.padding-top-offset {
  padding-top: 50px;
}
.drag-and-drop .k-master-row {
  cursor: pointer;
}
.rich-grid .k-header {
  text-transform: capitalize;
  text-align: center;
}
.data-operation .rich-grid td .k-dialog-content, .button-panel .k-dialog-content  {
  width: 600px;
}
.data-operation .process-option .mapping-rule-pop .k-dialog-content {
  width: 100%;
}
.search-criteria-grid tr {
  cursor: pointer;
}
.grid-mapper legend {
  padding: 0;
  font-size: 14px;
  text-align: center;
  margin: 5px 0;
}
.engine-dropdowns {
  width: 40%
}
.grid-operation .dataViewer .card{
  margin: 0;
}
.proc-report-ods .CodeMirror {
  height: 200px;
}
.process-option.reference .note {
  font-size: 12px;
  font-style: italic;
}
/*.grid-operation .dataViewer .rich-grid .am-rich-grid-table{
    display: block !important;
    -ms-flex-direction: unset;
    flex-direction: unset;
    width: 100%;
}
.grid-operation .dataViewer table {

}
*/
body .data-operation .k-dialog-wrapper .k-dialog {
  width: calc(100% - 10px);
  height: calc(100vh - 20px);
}
.full-width-table .am-rich-grid-tabletable {
  width: 100% !important;
}
.pivot-grid .full-width-table tr.k-master-row:last-child {
  height: calc(100vh - 340px) !important;
}

.pivot-grid .full-width-table .k-grid-content-locked tr:last-child td, .pivot-grid .full-width-table .k-grid-content tr:last-child td {
  vertical-align: initial;
}
body .process-option .am-form-inline .small-width .am-form-field > .am-combobox {
  width: 164px !important
}
.dataviewer-grid > .field_group .am-form-field {
    display: inline-block;
}
.m-l-5 {
  margin-left: 5px !important;
}
.p-r-0 {
  padding-right: 0px !important;
}
.p-t-b-4 {
  padding: 4px 0 !important;
}
.width-half {
  width: 50% !important;
}
.width-half .width-half {
  width: 100% !important;
}
.data-operation .rich-grid td .value-mapping-button .k-dialog-content, .data-operation .rich-grid td.expression-dialog-button-wrapper .annotate-contextmenu-applyProcessFlow-dialog .k-dialog-content {
  width: 100%
}
.data-operation .rich-grid td .value-mapping-button .k-dialog-content .k-grid-container .k-grid-content td {
  padding-left: 5px;
}
.data-operation .process-option.standard .join_master fieldset > .field_group{
  width: 555px;
}
.data-operation .process-option.standard .right-grid.join_master fieldset > .field_group, .data-operation .process-option.standard-two .right-grid.join_master fieldset > .field_group{
  float: right;
}
.data-operation .process-option.standard-two .join_master fieldset > .field_group{
  width: 634px;
}
.pivot-order-dialog .dropdown-list > span.k-header {
  width: 78% !important;
}

.pivot-order-dialog .dropdown-list.pivot-list > span.k-header {
  width: 170px !important;
  margin-right: 10px;
}
.m-r-10 {
  margin-right: 10px;
}
.executealljobs .am-textbox:disabled, .executealljobs .am-textbox[disabled], .executealljobs .am-textbox.k-state-disabled {
  background-color: #d4cece;
}
.data-operation .width-70 {
  width: 70px;
}
.data-operation .graph-wrapper .unit {
  margin-left: 2px;
  font-weight: 600;
}

.data-operation .m-l-50 {
  margin-left: 50px;
}
.data-operation .graph-wrapper .action-bar .k-multiselect.k-header {
  width: 300px !important;
}
.data-operation .graph-wrapper .container-wrapper {
   height: calc(100vh - 310px);
   overflow: auto;
   padding-top: 10px;
 }
 .data-operation .graph-wrapper .container-wrapper .container-node-wrapper {
   float: left;
   margin-right: 25px;
   width: 100%;
   height: calc(100vh - 375px);
   margin-bottom: 10px;
 }
 .data-operation .graph-wrapper .container-wrapper .container-node-wrapper .definition-wrapper {
   margin-top: 5px;
 }
.data-operation .graph-wrapper .container-wrapper .container-node-wrapper .k-tabstrip-top {
  width: 100%;
  height: 100%
}
.data-operation .graph-wrapper .unit .am-dropdown.k-header {
  width: 75px;
}
.data-operation .graph-wrapper .metrics-label {
  margin-left: 1px;
  margin-right: 10px;
  font-weight: 600;
}
.data-operation iframe.html-preview {
  width: 100%;
  height: calc(100vh - 340px);
  border: 1px solid #fccc;
  border-top: 0;
  pointer-events: none;
}

.data-operation .increase-grid-height iframe.html-preview {
  height: calc(100vh - 255px);
}
.data-operation .pivot-order-dialog .am-rich-grid-table .rgt-cell {
  border-bottom: 1px solid #ccc;
}
.data-operation .pivot-order-dialog .rich-grid .k-grid-table tr td .m-t-5 .m-t-5 {
  margin-top: 0;
}
.data-properties.manage-version .form-textbox.blockName {
  width: 75%
}
.data-properties.manage-version .form-textbox.blockid {
  width: 68%
}
.m-r-5-i {
  margin-right: 5px !important;
}
.m-r-10-i {
  margin-right: 10px !important;
}
.m-r-15-i {
  margin-right: 15px !important;
}
.process-option.report-operation .k-content.k-state-active .k-animation-container {
  height: 334px;
  overflow-y: auto;
}
body .data-operation .validation_error .k-dialog-wrapper .k-dialog  {
  width: 50%;
  height: auto;
  max-height: calc(100vh - 170px);
}
body .data-operation .validation_error .error-list {
  text-align: left;
  color: red;
  font-size: 14px;
  padding-left: 20px;
  margin-top: 5px;
}
body .data-operation .validation_error .error-list li {
  list-style: square;
  margin-bottom: 5px;
}
.data-operation.extended-attributes .ui-dialog-buttonpane .ui-dialog-buttonset {
  width: 100%;
  text-align: center;
}
.data-operation.main .ui-dialog-buttonpane .ui-dialog-buttonset {
  display: contents;
}
body .process-option .am-form-inline .am-form-field > .mh .am-combobox {
  width: 254px !important
}
body .process-option .Reporting_Info .am-form-inline .am-form-field > .am-combobox.width-no-flex-200, body .process-option .am-form-inline .am-form-field > .am-combobox.width-no-flex-200 {
    width: 200px  !important;
    flex: none !important;
}
.k-combobox-clearable.m-r-15 {
  margin-right: 15px !important;
}
.link-ops .m-r-10 {
  margin-right: 10px !important
}
.w-105 {
  width: 105px !important;
}
.w-800 {
  width: 800px;
}
.move-action-button {
  position: absolute;
  width: 40px;
  left: 48.6%;
  bottom: 35%;
}
.row-clickable .row-black {
  background-color: black !important;
  color: #fff !important;
}
.am-rich-grid-table .row-black.rgt-cell .rgt-cell-inner {
  color: #fff !important;
}
.process-option.data-ops .height_fixed_tabs .am-rich-grid-table {
  height: calc(100vh - 240px) !important;
}
.process-option.reference .am-dropdown .Dropdown-placeholder {
  width: inherit;
}
.join-type.am-dropdown .Dropdown-control .Dropdown-placeholder.is-selected {
  width: 340px;
}
